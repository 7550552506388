import "./Styles/EditProgramDayDetails.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import timerIcon from "../Images/Programs/timer.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Select from "react-select";
import Compressor from "compressorjs";

import Cropper from "react-cropper";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function EditProgramDayDetails() {
  //choose equipment starts
  const [selectedValue, setSelectedValue] = useState([]);
  console.log(selectedValue, "selectedValue");

  const handleChangeEquipment = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  //Upload image to AWS Start
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  let params = useParams();
  var weekNumber = params.weekNumber;
  var dayId = params.dayId;
  var programId = params.programId;
  const [equipmentArray, setEquipmentArray] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditEquipment, setIsEditEquipment] = useState(false);
  console.log(isEditEquipment, "isEditEquipment");
  const [dayNumber, setDayNumber] = useState(0);
  const [dayTitle, setDayTitle] = useState("");
  const [daySubtitle, setDaySubtitle] = useState("");
  const [dayTimeLength, setDayTimeLength] = useState("");
  const [dayDescription, setDayDescription] = useState("");
  const [dayImageUrl, setDayImageUrl] = useState("");
  const [thumbnaildayImageUrl, setThumbnaildayImageUrl] = useState("");
  const [dayEquipments, setDayEquipments] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [key, setKey] = useState("");
  const cropperRef = createRef();
  const [showReactCropper, setShowReactCropper] = useState(false);
  const [prevImg, setPrevImg] = useState();
  const [fileupdated, setFileupdated] = useState();
  const [awsfile, setAwsFile] = useState();
  const [awsThumbnail, setAwsThumbnail] = useState();
  const [videoUrlVimeo, setVideoUrlVimeo] = useState();

  var data = [];
  for (var i = 0; i < allEquipments.length; i++) {
    data.push({
      value: allEquipments[i].equipmentId,
      label: allEquipments[i].equipmentName,
    });
  }

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get(`/days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        console.log(response);
        setDayNumber(response.data.dayNumber);
        setDayTitle(response.data.dayTitle);
        setDaySubtitle(response.data.daySubtitle);
        setDayTimeLength(response.data.dayTimeLength);
        setDayDescription(response.data.dayDetails);
        setDayImageUrl(response.data.dayImageUrl);
        setThumbnaildayImageUrl(response.data.dayThumbnailImageUrl);
        // setThumbnail(response.data.dayThumbnailImageUrl);
        setAwsFile(response.data.dayImageUrl);
        setAwsThumbnail(response.data.dayThumbnailImageUrl);
        setDayEquipments(response.data.equipments);
        setVideoUrlVimeo(response?.data?.dayVideoUrl);
      });

    api
      .get("/Equipments/get-all-equipments", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllEquipments(response.data);
      });
  }, []);

  const fetchIndividualDay = () => {
    api
      .get(`/days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDayTitle(response.data.dayTitle);
        setDaySubtitle(response.data.daySubtitle);
        setDayTimeLength(response.data.dayTimeLength);
        setDayDescription(response.data.dayDetails);
        setDayImageUrl(response.data.dayImageUrl);
        setThumbnaildayImageUrl(response.data.dayThumbnailImageUrl);
        // setThumbnail(response.data.dayThumbnailImageUrl);
        setDayEquipments(response.data.equipments);
        setAwsFile(response.data.dayImageUrl);
        setAwsThumbnail(response.data.dayThumbnailImageUrl);
        setVideoUrlVimeo(response?.data?.dayVideoUrl);
      });
  };

  const handleUpdateDay = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (file === null) {
      await api
        .post(
          "/days/edit-day",
          {
            dayId: dayId,
            dayNumber: dayNumber,
            dayTitle: dayTitle,
            daySubtitle: daySubtitle,
            dayTimeLength: dayTimeLength,
            dayDetails: dayDescription,
            dayImageUrl: dayImageUrl,
            dayThumbnailImageUrl: awsThumbnail,
            dayVideoUrl: videoUrlVimeo,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setisSuccess(true);
          fetchIndividualDay();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(false);
          setIsLoading(false);
        });
    } else {
      console.log(file);
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);

      await api
        .post(
          "/days/edit-day",
          {
            dayId: dayId,
            dayNumber: dayNumber,
            dayTitle: dayTitle,
            daySubtitle: daySubtitle,
            dayTimeLength: dayTimeLength,
            dayDetails: dayDescription,
            dayImageUrl: imageResponse.Location,
            dayThumbnailImageUrl: thumbnailResponse.Location,
            dayVideoUrl: videoUrlVimeo,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setisSuccess(true);
          fetchIndividualDay();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(false);
          setIsLoading(false);
        });
    }
  };

  const handleUpdateEquipment = async () => {
    api
      .post(
        "/days/update-day-equipment",
        {
          dayId: dayId,
          equipments: selectedValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        fetchIndividualDay();
        setSelectedValue([]);
        setIsEditEquipment(false);
      })
      .catch((err) => {
        setSelectedValue([]);
        setIsEditEquipment(false);
      });
  };

  const handleRemoveImage = () => {
    setDayImageUrl(null);
    setFile(null);
    setThumbnail(null);
  };

  const hiddenInputImageUpload = useRef(null);

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };

  // const handleChangeUploadedImage = (e) => {
  //     if (e.target.files.length > 0) {
  //         setFile(e.target.files[0]);
  //         const imageUrl = URL.createObjectURL(e.target.files[0]);
  //         setDayImageUrl(imageUrl);
  //     }

  // }

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setDayImageUrl(prevImg);
    setThumbnail(prevImg);
    setFile(null);
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 100);

        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );

        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );

        setDayImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setThumbnail(newFile);

      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setFileupdated(newFile);
      setPrevImg(dayImageUrl);
      setDayImageUrl(URL.createObjectURL(newFile));

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }

      setShowReactCropper(true);
    }
  };

  function handleEquipmentId(equipmentId) {
    if (!equipmentArray.includes(equipmentId)) {
      var tempEquipmentArray = [];
      for (var i = 0; i < equipmentArray.length; i++) {
        tempEquipmentArray.push(equipmentArray[i]);
      }
      tempEquipmentArray.push(equipmentId);
      setEquipmentArray(tempEquipmentArray);
    } else {
      const index = equipmentArray.indexOf(equipmentId);
      const x = equipmentArray.splice(index, 1);
    }
  }

  const handleCancelEquipment = () => {
    setSelectedValue([]);
    setIsEditEquipment(false);
  };

  return (
    <div className="editProgramDayDetailsPage">
      {isSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Day details has been updated
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              successfully.
            </li>
            <button
              onClick={() => setisSuccess(false)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#ffba26",
                color: "#0a2e28",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendMedium500",
                fontSize: 14,
                borderRadius: 4,
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}

      {isEditEquipment && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              Choose Equipments
            </li>

            <Select
              className="dropdown"
              placeholder="Select Equipment"
              value={data.filter((obj) => selectedValue.includes(obj.value))} // set selected values
              options={data} // set list of the data
              onChange={handleChangeEquipment} // assign onChange function
              isMulti
              isClearable
            />
            <button
              onClick={handleUpdateEquipment}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#F38CAC",
                color: "white",
                paddingTop: 8,
                paddingBottom: 8,
                border: 0,
                fontFamily: "LexendBold700",
                fontSize: 16,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 10,
                width: 100,
              }}
            >
              Save
            </button>
            <button
              onClick={handleCancelEquipment}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 8,
                paddingBottom: 8,
                border: 0,
                fontFamily: "LexendBold700",
                fontSize: 16,
                borderRadius: 4,
                width: 100,
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}

      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            {" "}
            <span style={{ color: "#88869A" }}>Week {weekNumber}</span> - Day{" "}
            {dayNumber} Edit Details
          </li>
          <input
            key={key}
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <form
            onSubmit={handleUpdateDay}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              {dayImageUrl === null ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the program
                  </li>
                </div>
              ) : (
                <div>
                  {showReactCropper ? (
                    <div id="modal10" className="cropper-modal-wrapper">
                      {/* <ReactCrop
                      className="react-cropper"
                      src={programImageUrl}
                      crop={crop}
                      onChange={handleCropChange}
                      onComplete={handleCropComplete}

                      aspect={16 / 11}
                    >
                      <img src={programImageUrl} />
                    </ReactCrop>  */}
                      <Cropper
                        ref={cropperRef}
                        // style={{ height: 400, width: "100%" }}
                        zoomTo={0}
                        initialAspectRatio={16 / 11}
                        aspectRatio={16 / 11}
                        preview=".img-preview"
                        src={dayImageUrl}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                        className="croppper-body"
                      />
                      <button
                        onClick={cancleCropperModal}
                        className="cropper-cancel-btn"
                      >
                        Cancel
                      </button>
                      <button className="cropper-submit" onClick={getCropData}>
                        Save
                      </button>
                    </div>
                  ) : (
                    <>
                      <div onClick={handleClickUploadImage} style={{}}>
                        <img
                          src={dayImageUrl}
                          alt="uploadedImage"
                          style={{
                            width: 200,
                            height: 200,
                            resize: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <button
                        onClick={handleRemoveImage}
                        style={{
                          backgroundColor: "white",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 10,
                        }}
                      >
                        Remove Image
                      </button>
                    </>
                  )}
                </div>
              )}
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="Day Title"
                  value={dayTitle}
                  onChange={(e) => setDayTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  placeholder="Day Small Description"
                  value={daySubtitle}
                  onChange={(e) => setDaySubtitle(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <img
                    src={timerIcon}
                    alt="timerIcon"
                    style={{
                      position: "absolute",
                      maxHeight: 18,
                      marginLeft: 20,
                      marginTop: 35,
                    }}
                  />
                  <input
                    required
                    placeholder="Time Length"
                    value={dayTimeLength}
                    onChange={(e) => setDayTimeLength(e.target.value)}
                    style={{
                      marginTop: 25,
                      width: 315,
                      height: 40,
                      borderStyle: "none",
                      paddingLeft: 45,
                      fontFamily: "LexendRegular400",
                    }}
                  />
                </div>
                <li
                  style={{
                    fontSize: 12,
                    marginTop: 5,
                    fontFamily: "LexendBold700",
                  }}
                >
                  *Format: MM:SS
                </li>
              </div>
              <div style={{ marginLeft: 88 }}>
                {!isLoading ? (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                <button
                  onClick={() =>
                    navigate(
                      `/edit-program/follow-along/${programId}/${dayId}/${dayNumber}`
                    )
                  }
                  style={{
                    marginTop: 25,
                    width: 140,
                    height: 50,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "#EAF0FF",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                    color: "#4176FF",
                    borderStyle: "none",
                  }}
                >
                  Follow Along
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `/edit-program/follow-along/${programId}/${dayId}/${dayNumber}/home-clip`
                    )
                  }
                  style={{
                    marginTop: 25,
                    width: 140,
                    height: 50,
                    display: "block",
                    backgroundColor: "#FFF2F2",
                    color: "#FF0000",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                    borderStyle: "none",
                  }}
                >
                  Home Clip
                </button>
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>

            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              Day Description
            </li>
            <textarea
              placeholder="Description"
              value={dayDescription}
              onChange={(e) => setDayDescription(e.target.value)}
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                width: 570,
                height: 150,
                backgroundColor: "#ffffff",
                borderStyle: "none",
                fontFamily: "LexendRegular400",
                fontSize: 14,
              }}
            ></textarea>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 0,
                marginBottom: 25,
              }}
            >
              Equipment's Needed
            </li>
            <button
              onClick={() => setIsEditEquipment(true)}
              style={{
                height: 50,
                width: 140,
                fontFamily: "LexendBold700",
                fontSize: 18,
                borderStyle: "none",
                background: "#FFF2F2",
                color: "#FF0000",
              }}
            >
              Edit
            </button>
          </div>
          <div style={{ marginTop: 25, display: "flex", flexWrap: "wrap" }}>
            {dayEquipments.map((val, key) => {
              return (
                <div
                  onClick={() => handleEquipmentId(val.equipmentId)}
                  id="equipmentBox"
                  key={key}
                  style={{
                    width: 150,
                    height: 135,
                    marginRight: 20,
                    marginBottom: 30,
                    borderColor: "blue",
                    borderRadius: 20,
                    borderStyle: equipmentArray.includes(val.equipmentId)
                      ? "solid"
                      : "none",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "none",
                      marginLeft: 20,
                      marginRight: 10,
                      marginBottom: 10,
                      height: 80,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={val.equipmentImage}
                      alt="home"
                      style={{ marginTop: 30, width: 100, height: 50 }}
                    />
                  </div>
                  <li
                    style={{
                      marginLeft: 20,
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                    }}
                  >
                    {val.equipmentName}
                  </li>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
