import './Styles/AdminDetails.css';
import {useState, useEffect} from 'react';
import LeftNavigationBar from '../Components/LeftNavigationBar';
import backIcon from '../Images/AddAdmin/back.png';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../Contexts/BaseUrl';
import {Spinner} from 'react-activity';
import "react-activity/dist/library.css";


export default function UserDetails(){

    let params = useParams();
    var adminId = params.adminId;

    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');

    const [userName, setUserName] = useState('');
    const [userNameInitial, setUserNameInitial] = useState('');
    
    const [packages, setPackages] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);


    useEffect(() => {
        var user = JSON.parse(localStorage.getItem('auth'));
        if(user){
            setToken(user.token);
        }

        api.get(`/accounts/get-account-holder?email=${adminId}`, {
                headers: {Authorization: `Bearer ${user.token}`}
            }).then(response => {
                setUserName(response.data.name);  
                setUserNameInitial(response.data.name);         
            })

        api.get(`/subscriptionpackages/get-all-packages`, {
            headers: {Authorization: `Bearer ${user.token}`}
        }).then(response => {
            setPackages(response.data);
        })    

    },[])
    
    
    const fetchUserDetails = () => {
        api.get(`/accounts/get-account-holder?email=${adminId}`, {
            headers: {Authorization: `Bearer ${token}`}
        }).then(response => {
            setUserName(response.data.name);
        })
    }
   
    const handleUpdateAdminDetails = async (e) => {
        e.preventDefault();
        if(userName !== userNameInitial && selectedRole.length > 0 && selectedRole !== 'choose'){
            await api.post("/roles/change-role", {
                email: adminId,
                roleName: selectedRole,
            }, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                setIsSuccessful(true);
            })

            await api.post("/accounts/edit-user-details", {
                email: adminId,
                name: userName,
            }, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                setIsSuccessful(true);
                navigate(-1);
            })
        }
        else if(selectedRole.length > 0 && selectedRole !== 'choose'){
            await api.post("/roles/change-role", {
                email: adminId,
                roleName: selectedRole,
            }, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                setIsSuccessful(true);
                navigate(-1);
            })
           
        }else{
            await api.post("/accounts/edit-user-details", {
                email: adminId,
                name: userName,
            }, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                setIsSuccessful(true);
                navigate(-1);
            })
        }
        
    }

    return(
        <div className='userDetailsPage'>
            <LeftNavigationBar allAdmins={1} />
            <form className='mainBody' onSubmit={handleUpdateAdminDetails} >
             <div>  
                <div style ={{ height: 50 }} />
                <div onClick={() => navigate('/all-admins')} style={{cursor: 'pointer' ,marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', width: 40, height: 40, borderRadius: 25, borderStyle: 'solid', borderColor: '#F38CAC', borderWidth: 1 }}>
                    <img src={backIcon} alt='backicon' style={{ maxHeight: 12 }} />
                </div>
                
                <li style={{marginBottom: 20, fontSize: 24, fontFamily: 'LexendBold700' }}>Admin Details</li>
                <input
                    type='text'
                    required
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    placeholder='Name'
                    style={{marginBottom: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, width: 300, fontSize: 14, fontFamily: 'LexendRegular400' }}
                />
                <br />
               
                <div style={{background: '#FFF2F2', marginBottom: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, width: 300, height: 20, fontSize: 14, fontFamily: 'LexendRegular400' }}>
                    <li style={{ fontSize: 14, fontFamily: 'LexendRegular400' }}>{adminId}</li>
                </div>
                
                <select id='roles' name='roles' onChange={(e) => setSelectedRole(e.target.value)}  style={{marginBottom: 30, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, width: 320, borderStyle: 'none', fontFamily: 'LexendRegular400', }}>
                    <option value='choose'>Choose Role</option>
                    <option value='Admin'>Admin</option>
                    <option value='Superadmin'>Superadmin</option>
                </select>

               
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button style={{ width: 140, height: 40, display: 'block',  backgroundColor: '#FFF2F2', fontFamily: 'LexendExtraBold800', fontSize: 14, color: '#FF0000', borderStyle: 'none' }}>Delete</button>        
                <button style={{ width: 140, height: 40, display: 'block', backgroundColor: '#4176FF', fontFamily: 'LexendExtraBold800', fontSize: 14, color: '#ffffff', borderStyle: 'none' }}>Save Changes</button> 
                </div>
            </div>
            
                
            </form>
        </div>
    )
}