import "./Styles/Login.css";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

export default function ForgetPassword() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Wrong Email or Password!");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const refresh = localStorage.getItem("refresh");
  if (refresh != null) {
    localStorage.removeItem("refresh");
    window.location.reload();
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await api
      .post("/accounts/forgot-password", {
        email: email,
      })
      .then((response) => {
        setIsLoading(false);
        setIsForgotPassword(true);
        // navigate("/reset-password");
      });
  };

  return (
    <>
      {isForgotPassword && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center" }}>Password reset link has</li>
            <li style={{ textAlign: "center" }}>been sent to your email</li>
            <button
              onClick={() => setIsForgotPassword(false)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: 80,
                marginTop: 20,
                borderStyle: "none",
                height: 35,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                backgroundColor: "#F38CAC",
                color: "white",
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      <div className="loginPage" style={{ height: windowSize.current[1] }}>
        <div id="leftSide" style={{ height: windowSize.current[1] }}>
          <div>
            <h4 style={{ fontFamily: "LexendBold700", fontSize: 34 }}>
              {" "}
              Muscle Sisters{" "}
            </h4>
            <div style={{ position: "absolute", bottom: 10 }}>
              <span
                onClick={() => navigate("/privacy-policy")}
                style={{ cursor: "pointer", margin: 10 }}
              >
                Privacy Policy
              </span>
              <span
                onClick={() => navigate("/terms")}
                style={{ cursor: "pointer" }}
              >
                Terms & Conditions
              </span>
            </div>
          </div> 
        </div>
        <div id="rightSide">
          <div id="loginBox">
            <form id="loginForm" onSubmit={handleForgotPassword}>
              <li style={{ fontFamily: "LexindMedium500", fontSize: 28 }}>
                Forget Password
              </li>
              {isError && (
                <li
                  style={{
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                    color: "red",
                    marginTop: 5,
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </li>
              )}
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                style={{
                  width: "95%",
                  paddingLeft: 20,
                  paddingTop: 15,
                  paddingBottom: 15,
                  marginTop: 20,
                  backgroundColor: "#FAFAFD",
                  borderStyle: "none",
                  borderRadius: 9,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                }}
              />

              {!isLoading ? (
                <button
                  onClick={() => handleForgotPassword()}
                  style={{ backgroundColor: "#EAF0FF", color: "#FF0000" }}
                  id="buttonUserProfile"
                >
                  Forgot Password
                </button>
              ) : (
                <button>
                  <Spinner
                    size={15}
                    backgroundColor="#EAF0FF"
                    color="#FF0000"
                    style={{ diplay: "block", margin: "auto" }}
                  />
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      .
    </>
  );
}
