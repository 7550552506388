import "./Styles/EditProgramDetails.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { AiOutlinePlus } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import Select from "react-select";
import Compressor from "compressorjs";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function EditProgramDetails() {
  //Upload image to AWS Start
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  let params = useParams();
  var programId = params.programId;
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const cropperRef = createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAddWeek, setIsAddWeek] = useState(false);
  const [programTitle, setProgramTitle] = useState("");
  const [programSubtitle, setProgramSubtitle] = useState("");
  const [programTimeLength, setProgramTimeLength] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programImageUrl, setProgramImageUrl] = useState(null);
  const [awsfile, setAwsFile] = useState();
  const [awsThumbnail, setAwsThumbnail] = useState();
  const [categories, setCategories] = useState([]);
  const [weeks, setWeeks] = useState([]);

  //add categories to program starts
  const [allCategories, setAllCategories] = useState([]);
  const [isEditCategories, setIsEditCategories] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [showReactCropper, setShowReactCropper] = useState(false);

  const handleChangeEquipment = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  var data = [];
  for (var i = 0; i < allCategories.length; i++) {
    data.push({
      value: allCategories[i].workoutProgramCategoryId,
      label: allCategories[i].workoutProgramCategoryName,
    });
  }

  const handleCancelCategories = () => {
    setSelectedValue([]);
    setIsEditCategories(false);
  };

  const handleUpdateCategories = async () => {
    api
      .post(
        "/workoutPrograms/update-program-category",
        {
          workoutProgramId: programId,
          workoutProgramCategories: selectedValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        fetchIndividualProgram();
        setSelectedValue([]);
        setIsEditCategories(false);
      })
      .catch((err) => {
        setSelectedValue([]);
        setIsEditCategories(false);
      });
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get(`/workoutPrograms/get-individual-program?id=${programId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setProgramTitle(response.data.workoutProgramTitle);
        setProgramSubtitle(response.data.workoutProgramSubtitle);
        setProgramTimeLength(response.data.workoutProgramTimeLength);
        setProgramDescription(response.data.workoutProgramDescription);
        setProgramImageUrl(response.data.workoutProgramImageUrl);
        setThumbnail(response.data.workoutProgramThumbnailUrl);
        setAwsFile(response.data.workoutProgramImageUrl);
        setAwsThumbnail(response.data.workoutProgramThumbnailUrl);
        setCategories(response.data.categories);
        setWeeks(response.data.weeks);
      });

    api
      .get("/workoutProgramCategories/get-all-categories", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllCategories(response.data);
      });
  }, []);

  const fetchIndividualProgram = () => {
    api
      .get(`/workoutPrograms/get-individual-program?id=${programId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setProgramTitle(response.data.workoutProgramTitle);
        setProgramSubtitle(response.data.workoutProgramSubtitle);
        setProgramTimeLength(response.data.workoutProgramTimeLength);
        setProgramDescription(response.data.workoutProgramDescription);
        setProgramImageUrl(response.data.workoutProgramImageUrl);
        setThumbnail(response.data.workoutProgramThumbnailUrl);
        setAwsFile(response.data.workoutProgramImageUrl);
        setAwsThumbnail(response.data.workoutProgramThumbnailUrl);
        setCategories(response.data.categories);
        setWeeks(response.data.weeks);
      });
  };

  const [timeValidation, setTimeValidation] = useState("");
  const [isTimeValid, setIsTimeValid] = useState(true);

  const validateTimeInput = () => {
    const timeRegex = /^[\d:]+$/;
    if (!timeRegex.test(programTimeLength)) {
      setIsTimeValid(false);
    } else {
      setIsTimeValid(true);
      setTimeValidation("");
    }
  };

  const handleUpdateProgram = async (e) => {
    e.preventDefault();
    validateTimeInput();
    if (isTimeValid) {
      setIsLoading(true);
      if (file === null) {
        await api
          .post(
            "/workoutPrograms/edit-program",
            {
              workoutProgramId: programId,
              workoutProgramTitle: programTitle,
              workoutProgramSubtitle: programSubtitle,
              workoutProgramTimeLength: programTimeLength,
              workoutProgramDescription: programDescription,
              workoutProgramImageUrl: programImageUrl,
              workoutProgramThumbnailUrl: awsThumbnail,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setisSuccess(true);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsError(false);
            setIsLoading(false);
          });
      } else {
        const imageResponse = await uploadImage(file);
        const thumbnailResponse = await uploadImage(thumbnail);

        console.log(thumbnailResponse);

        await api
          .post(
            "/workoutPrograms/edit-program",
            {
              workoutProgramId: programId,
              workoutProgramTitle: programTitle,
              workoutProgramSubtitle: programSubtitle,
              workoutProgramTimeLength: programTimeLength,
              workoutProgramDescription: programDescription,
              workoutProgramImageUrl: imageResponse.Location,
              workoutProgramThumbnailUrl: thumbnailResponse.Location,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setisSuccess(true);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsError(false);
            setIsLoading(false);
          });
      }
    } else {
      setTimeValidation("Please enter valid time format");
    }
  };

  const handleAddWeek = async (e) => {
    e.preventDefault();
    setIsAddWeek(true);
    console.log(weeks);
    var weekNo = weeks?.length ? weeks[weeks.length - 1].weekNumber + 1 : 1;
    await api
      .post(
        `/weeks/create-week-days?programId=${programId}&weekNumber=${weekNo}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // handleCreateWeekDays(response.data.weekId);
        setIsLoading(false);
        setisSuccess(false);
        setIsAddWeek(false);

        fetchIndividualProgram();
      })
      .catch((err) => {
        setIsAddWeek(false);
      });
  };

  const [isDeleteWeek, setIsDeleteWeek] = useState(false);
  const [deleteWeekId, setDeleteWeekId] = useState(0);

  function handleDeleteWeekInitial(weekId) {
    setIsDeleteWeek(true);
    setDeleteWeekId(weekId);
  }

  const handleDeleteWeekFinal = async () => {
    await api
      .post(`/weeks/delete-week?weekId=${deleteWeekId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((reponse) => {
        fetchIndividualProgram();
        setIsDeleteWeek(false);
      })
      .catch((err) => {
        setIsDeleteWeek(false);
      });
  };

  const handleRemoveImage = () => {
    setProgramImageUrl(null);
    setFile(null);
    setThumbnail(null);
  };

  const hiddenInputImageUpload = useRef(null);
  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };
  const [key, setKey] = useState("");

  // const handleChangeUploadedImage = (e) => {
  //   console.log("File selected:", e.target.files[0]);
  //   if (e.target.files.length > 0) {
  //     const newFile = e.target.files[0];
  //     // setFile(newFile);

  //     const newKey = Math.random().toString(36).substring(7);
  //     setKey(newKey);
  //     new Compressor(newFile, {
  //       quality: 0.8,
  //       success: (compressedResult) => {
  //         setProgramImageUrl(URL.createObjectURL(compressedResult));
  //         setFile(compressedResult);
  //       },
  //     });
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setProgramImageUrl(reader.result);
  //     };
  //     reader.readAsDataURL(newFile);

  //     setFile(newFile);

  //     if (hiddenInputImageUpload.current) {
  //       hiddenInputImageUpload.current.value = '';
  //     }
  //   }
  // };

  const [fileupdated, setFileupdated] = useState();
  const [prevImg, setPrevImg] = useState();

  //   const resizeFile = (file) => new Promise(resolve => {

  //     // Resizer.imageFileResizer(file, 300, 300, 100, 0,
  //     // uri => {
  //     //   resolve(uri);
  //     // }, 'base64',
  //     //  );

  //      if (file) {
  //       console.log("File received", file);
  //       try {
  //         Resizer.imageFileResizer(
  //           file,
  //           500,
  //           500,
  //           90,
  //           0,
  //           (blob) => {
  //             handleImage({
  //               url: URL.createObjectURL(blob),
  //               size: blob.size,
  //               type: blob.type
  //             });
  //           },
  //           "blob",
  //           400,
  //           400
  //         );
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     }
  // });

  // const handleImage = (image) => {
  // setNewImage(image);
  // };

  const handleChangeUploadedImage = async (e) => {
    console.log("File selected:", e.target.files[0]);

    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      // const image = await resizeFile(newFile);
      // const resizedImage = await resizeImage(newFile);
      // new Compressor(newFile, {
      //   size: 0.5,
      //   quality: 0,
      //   maxWidth: 200,
      //   maxHeight: 200,
      //   success: (compressedResult) => {
      setFile(newFile);
      setThumbnail(newFile);
      //   },
      // });
      // setFile(resizedImage);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);

      setFileupdated(newFile);

      setPrevImg(programImageUrl);
      setProgramImageUrl(URL.createObjectURL(newFile));
      setFile(newFile);
      setThumbnail(newFile);
      // const reader = new FileReader();

      // reader.onload = function (e) {
      //   const image = new Image();
      //   image.src = e.target.result;
      //   image.onload = function () {
      //     const canvas = document.createElement("canvas");
      //     const ctx = canvas.getContext("2d");

      //     const targetAspectRatio = 16 / 11;
      //     const imageAspectRatio = image.width / image.height;

      //     let newWidth, newHeight;

      //     if (imageAspectRatio > targetAspectRatio) {
      //       newWidth = image.height * targetAspectRatio;
      //       newHeight = image.height;
      //     } else {
      //       newWidth = image.width;
      //       newHeight = image.width / targetAspectRatio;
      //     }

      //     canvas.width = newWidth;
      //     canvas.height = newHeight;

      //     const xOffset = (image.width - newWidth) / 2;
      //     const yOffset = (image.height - newHeight) / 2;

      //     ctx.drawImage(
      //       image,
      //       xOffset,
      //       yOffset,
      //       newWidth,
      //       newHeight,
      //       0,
      //       0,
      //       newWidth,
      //       newHeight
      //     );

      //     canvas.toBlob((blob) => {
      //       const fixedAspectRatioImage = new File([blob], newFile.name, {
      //         type: newFile.type,
      //       });
      //       setPrevImg(programImageUrl);
      //       setProgramImageUrl(URL.createObjectURL(fixedAspectRatioImage));
      //       setFile(fixedAspectRatioImage);
      //     }, newFile.type);
      //   };
      // };

      // reader.readAsDataURL(newFile);

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
      setShowReactCropper(true);
    }
  };

  useEffect(() => {
    if (showReactCropper) {
      // Set overflow to hidden on the body element
      document.body.style.overflow = "hidden";
    } else {
      // Reset overflow to auto when showReactCropper is false
      document.body.style.overflow = "auto";
    }
  }, [showReactCropper]);

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setProgramImageUrl(awsfile);
    setFile(null);
    // setThumbnail(prevImg);
    // setFile(prevImg)
  };
  // const handleChangeUploadedImage = (e) => {
  //   if (e.target.files.length > 0) {
  //     const newFile = e.target.files[0];

  //     const newKey = Math.random().toString(36).substring(7);
  //     setKey(newKey);

  //     const image = new Image();
  //     const reader = new FileReader();

  //     reader.onload = function (e) {
  //       image.src = e.target.result;

  //       image.onload = function () {
  //         const canvas = document.createElement('canvas');
  //         const ctx = canvas.getContext('2d');

  //         const targetAspectRatio = 16 / 9;
  //         const imageAspectRatio = image.width / image.height;

  //         let newWidth, newHeight;

  //         if (imageAspectRatio > targetAspectRatio) {
  //           newWidth = image.height * targetAspectRatio;
  //           newHeight = image.height;
  //         } else {
  //           newWidth = image.width;
  //           newHeight = image.width / targetAspectRatio;
  //         }

  //         canvas.width = newWidth;
  //         canvas.height = newHeight;

  //         const xOffset = (image.width - newWidth) / 2;
  //         const yOffset = (image.height - newHeight) / 2;

  //         ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight, 0, 0, newWidth, newHeight);

  //         canvas.toBlob((blob) => {
  //           const compressedResult = new File([blob], newFile.name, { type: newFile.type });
  //           setProgramImageUrl(URL.createObjectURL(compressedResult));
  //           setFile(compressedResult);
  //           // setIsModal(true);
  //         }, newFile.type);
  //       };
  //     };

  //     reader.readAsDataURL(newFile);

  //     if (hiddenInputImageUpload.current) {
  //       hiddenInputImageUpload.current.value = '';
  //     }
  //   }
  // };

  const handleImageLoaded = (image) => {
    // Perform any necessary actions when the image is loaded
    console.log("Image loaded:", image);
  };

  const handleCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);

    // console.log("Crop changed:", crop);
  };

  const handleCropComplete = (crop, percentCrop) => {
    console.log("Crop completed:", percentCrop);
    // You can do something with the cropped pixel data if needed

    setCrop(percentCrop);
  };

  const [isWeekDays, setIsWeekDays] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [weekNumber, setWeekNumber] = useState(0);

  function handleWeekDays(weekId) {
    setIsWeekDays(true);
    api
      .get(`/weeks/get-individual-week?weekId=${weekId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setWeekDays(response.data.days);
        setWeekNumber(response.data.weekNumber);
      });
  }
  const [cropData, setCropData] = useState("#");

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 300);

        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );

        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );

        setProgramImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const handleCropSubmit = async (e) => {
    e.preventDefault();
    if (!file || !programImageUrl) {
      return;
    }

    const canvas = document.createElement("canvas");
    const image = new Image();
    image.src = programImageUrl;

    // Adjust the canvas size based on the cropped area
    console.log(crop, image);
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // Draw the cropped area onto the canvas
    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert the canvas content to a Blob (image file)
    canvas.toBlob((blob) => {
      const formData = new FormData();
      // formData.append("croppedImage", blob, "cropped-image.png");

      // setProgramImageUrl(formData)
      // setShowReactCropper(false)
      // Convert blob to data URL
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataUrl = reader.result;
        // Set the data URL in the state
        setProgramImageUrl(dataUrl);
        setShowReactCropper(false);
      };
      reader.readAsDataURL(blob);
    });
  };

  return (
    <div className="editProgramDetailsPage">
      {isSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Program has been updated
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              successfully.
            </li>

            <button
              onClick={() => navigate(-1)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#ffba26",
                color: "#0a2e28",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendMedium500",
                fontSize: 14,
                borderRadius: 4,
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      {isDeleteWeek && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Are you sure you want to
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              delete this week?
            </li>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={handleDeleteWeekFinal}
                style={{
                  marginRight: 8,
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteWeek(false)}
                style={{
                  marginLeft: 8,
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      {isEditCategories && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal4">
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              Choose Categories
            </li>

            <Select
              className="dropdown"
              placeholder="Select Categories"
              value={data.filter((obj) => selectedValue.includes(obj.value))} // set selected values
              options={data} // set list of the data
              onChange={handleChangeEquipment} // assign onChange function
              isMulti
              isClearable
            />

            <button
              onClick={handleUpdateCategories}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#F38CAC",
                color: "white",
                paddingTop: 8,
                paddingBottom: 8,
                border: 0,
                fontFamily: "LexendBold700",
                fontSize: 16,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 10,
                width: 100,
              }}
            >
              Save
            </button>
            <button
              onClick={handleCancelCategories}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 8,
                paddingBottom: 8,
                border: 0,
                fontFamily: "LexendBold700",
                fontSize: 16,
                borderRadius: 4,
                width: 100,
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {isWeekDays && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal3">
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 24,
                marginBottom: 15,
              }}
            >
              Edit Week {weekNumber} Days
            </li>
            <div
              style={{
                display: "flex",
                borderStyle: "none",
                justifyContent: "space-between",
              }}
            >
              {weekDays.map((val, index) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        navigate(
                          `/edit-program/${programId}/day-details/${weekNumber}/${val.dayId}`
                        )
                      }
                      key={index}
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        background: "#ffffff",
                        width: 120,
                        height: 100,
                        borderStyle: "dotted",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        {val.dayImageUrl !== null ? (
                          <img
                            src={val.dayImageUrl}
                            alt="day"
                            style={{ width: 120, height: 100, resize: "cover" }}
                          />
                        ) : (
                          <div>
                            <CiImageOn
                              size={34}
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                            <li
                              style={{
                                fontFamily: "LexendMedium500",
                                fontSize: 18,
                              }}
                            >
                              Day {val.dayNumber}
                            </li>
                          </div>
                        )}
                      </>
                    </div>
                  </>
                );
              })}
            </div>
            <button
              onClick={() => setIsWeekDays(false)}
              style={{
                width: 70,
                paddingTop: 5,
                paddingBottom: 5,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 15,
                background: "#F38CAC",
                color: "white",
                fontFamily: "LexendSemiBold600",
                fontSize: 16,
                borderStyle: "none",
              }}
            >
              Back
            </button>
          </div>
        </>
      )}

      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Edit Program Details
          </li>
          <input
            key={key}
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            onClick={handleClickUploadImage}
            style={{ display: "none" }}
          />

          <form
            onSubmit={handleUpdateProgram}
            style={{ marginTop: 30, marginBottom: 60 }}
          >
            <div style={{ display: "flex" }}>
              {programImageUrl === null ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the program
                  </li>
                </div>
              ) : (
                <div>
                  {showReactCropper ? (
                    <div id="modal10" className="cropper-modal-wrapper">
                      {/* <ReactCrop
                        className="react-cropper"
                        src={programImageUrl}
                        crop={crop}
                        onChange={handleCropChange}
                        onComplete={handleCropComplete}

                        aspect={16 / 11}
                      >
                        <img src={programImageUrl} />
                      </ReactCrop>  */}
                      <Cropper
                        ref={cropperRef}
                        // style={{ height: 400, width: "100%" }}
                        zoomTo={0}
                        initialAspectRatio={16 / 11}
                        aspectRatio={16 / 11}
                        preview=".img-preview"
                        src={programImageUrl}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                        className="croppper-body"
                      />
                      <button
                        onClick={cancleCropperModal}
                        className="cropper-cancel-btn"
                      >
                        Cancel
                      </button>
                      <button className="cropper-submit" onClick={getCropData}>
                        Save
                      </button>
                    </div>
                  ) : (
                    <>
                      <div onClick={handleClickUploadImage} style={{}}>
                        <img
                          src={programImageUrl}
                          alt="uploadedImage"
                          style={{
                            width: 200,
                            height: 200,
                            resize: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <button
                        onClick={handleRemoveImage}
                        style={{
                          backgroundColor: "white",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 10,
                        }}
                      >
                        Remove Image
                      </button>
                    </>
                  )}
                </div>
              )}
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="Program Title"
                  value={programTitle}
                  onChange={(e) => setProgramTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  placeholder="Small Description"
                  value={programSubtitle}
                  onChange={(e) => setProgramSubtitle(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <input
                    required
                    placeholder="No of days"
                    value={programTimeLength}
                    onChange={(e) => setProgramTimeLength(e.target.value)}
                    // onBlur={validateTimeInput}
                    style={{
                      marginTop: 25,
                      width: 340,
                      height: 40,
                      borderStyle: "none",
                      paddingLeft: 20,
                      fontFamily: "LexendRegular400",
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}

                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 60,
                marginBottom: 25,
              }}
            >
              Program Description
            </li>
            <textarea
              placeholder="Description"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                width: 570,
                height: 200,
                backgroundColor: "#ffffff",
                borderStyle: "none",
                fontFamily: "LexendRegular400",
                fontSize: 14,
              }}
            ></textarea>
          </form>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              Categories
            </li>
            <button
              onClick={() => setIsEditCategories(true)}
              style={{
                fontFamily: "LexendBold700",
                fontSize: 18,
                width: 140,
                height: 50,
                background: "#FFF2F2",
                borderStyle: "none",
                color: "#FF0000",
              }}
            >
              Edit
            </button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
            {categories === null || categories.length === 0 ? (
              <li style={{ fontFamily: "LexendMedium500" }}>
                No category has been added!
              </li>
            ) : (
              categories.map((val, key) => {
                return (
                  <div key={key} id="categoryBox">
                    <span
                      style={{ marginLeft: 0, fontFamily: "LexendMedium500" }}
                    >
                      {val.workoutProgramCategoryName}
                    </span>
                  </div>
                );
              })
            )}
          </div>

          <li
            style={{
              fontFamily: "LexendSemiBold600",
              fontSize: 24,
              marginTop: 50,
              marginBottom: 25,
            }}
          >
            Weeks
          </li>
          <div
            style={{
              marginTop: 20,
              fontFamily: "LexendSemiBold600",
              fontSize: 18,
              borderStyle: "none",
            }}
          >
            {weeks.map((val, key) => {
              return (
                <div
                  id="weekDayBox"
                  key={key}
                  style={{
                    height: 50,
                    borderStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#ffffff",
                    marginBottom: 20,
                  }}
                >
                  <li style={{ marginTop: 15, marginLeft: 10 }}>
                    Week {val.weekNumber}
                  </li>
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => handleWeekDays(val.weekId)}
                      style={{
                        fontFamily: "LexendSemiBold600",
                        fontSize: 14,
                        width: 100,
                        background: "#4176FF",
                        borderStyle: "none",
                        color: "white",
                        marginRight: 15,
                      }}
                    >
                      View Days
                    </button>
                    <button
                      onClick={() => handleDeleteWeekInitial(val.weekId)}
                      style={{
                        fontFamily: "LexendSemiBold600",
                        fontSize: 14,
                        width: 100,
                        background: "#FFF2F2",
                        borderStyle: "none",
                        color: "#FF0000",
                      }}
                    >
                      Delete Week
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {!isAddWeek ? (
            <button
              onClick={handleAddWeek}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                height: 50,
                width: "100%",
                borderStyle: "none",
              }}
            >
              <AiOutlinePlus size={18} />
              <li
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                Add Another Week
              </li>
            </button>
          ) : (
            <button
              onClick={handleAddWeek}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                height: 50,
                width: "100%",
                borderStyle: "none",
              }}
            >
              <Spinner
                size={18}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
