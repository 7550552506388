import { useEffect, useRef, useState } from "react";
import "./Styles/Home.css";
import api from "../Contexts/BaseUrl";
import LeftNavigationBar from "../Components/LeftNavigationBar";

export default function Home() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [token, setToken] = useState(null);

  const [totalWorkoutPrograms, setTotalWorkoutPrograms] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [countMonthly, setCountMonthly] = useState(0);
  const [countYearly, setCountYearly] = useState(0);
  const [countLifetime, setCountLifetime] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [yearlyTotal, setYearlyTotal] = useState(0);
  const [lifetimeTotal, setLifetimeTotal] = useState(0);
  const [lastDayTotal, setLastDayTotal] = useState(0);
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get("/workoutprograms/total-programs", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setTotalWorkoutPrograms(response.data);
      });

    api
      .get("/payments/total-revenue", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setTotalRevenue(response.data);
      });

    api
      .get("/payments/count-monthly-subscription", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setCountMonthly(response.data);
      });

    api
      .get("/payments/count-yearly-subscription", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setCountYearly(response.data);
      });

    api
      .get("/payments/count-lifetime-subscription", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setCountLifetime(response.data);
      });

    api
      .get("/payments/monthly-subscription-total", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setMonthlyTotal(response.data);
      });

    api
      .get("/payments/annual-subscription-total", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setYearlyTotal(response.data);
      });

    api
      .get("/payments/lifetime-subscription-total", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setLifetimeTotal(response.data);
      });

    api
      .get("/payments/get-last-day-payment", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setLastDayTotal(response.data);
      });
  }, []);

  const fetchMonthlyTotal = () => {
    api
      .get("/payments/monthly-subscription-total", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setMonthlyTotal(response.data);
      });
  };

  return (
    <div className="homePage">
      <LeftNavigationBar home={1} />

      <div
        style={{
          width: windowSize.current[0],
          marginLeft: 300,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="mainBody">
          <div style={{ marginTop: 50, width: 420, background: "#ffffff" }}>
            <li
              style={{
                marginLeft: 25,
                fontFamily: "LexendMedium500",
                fontSize: 24,
                paddingTop: 50,
                color: "#8f8d9f",
              }}
            >
              Total Programs
            </li>
            <li
              style={{
                marginLeft: 25,
                fontFamily: "LexendBold700",
                fontSize: 50,
                paddingTop: 10,
                paddingBottom: 50,
              }}
            >
              {totalWorkoutPrograms}
            </li>
          </div>
          <div style={{ display: "flex", marginTop: 50 }}>
            <div style={{ width: 420, background: "#ffffff" }}>
              <li
                style={{
                  marginLeft: 25,
                  fontFamily: "LexendMedium500",
                  fontSize: 24,
                  paddingTop: 50,
                  color: "#8f8d9f",
                  textAlign: "center",
                }}
              >
                Last 24hr Sell
              </li>
              <li
                style={{
                  marginLeft: 25,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 50,
                  paddingTop: 10,
                  textAlign: "center",
                }}
              >
                ${lastDayTotal.toFixed(2)}
              </li>
              <div style={{ width: "100%" }}>
                <li
                  style={{
                    marginLeft: 25,
                    fontFamily: "LexendMedium500",
                    fontSize: 22,
                    color: "#8f8d9f",
                    textAlign: "center",
                    paddingTop: 10,
                    paddingBottom: 50,
                  }}
                >
                  Total Sell
                </li>
              </div>
            </div>
            <div style={{ width: 420, background: "#ffffff", marginLeft: 60 }}>
              <li
                style={{
                  marginLeft: 25,
                  fontFamily: "LexendMedium500",
                  fontSize: 24,
                  paddingTop: 50,
                  color: "#8f8d9f",
                  textAlign: "center",
                }}
              >
                All Time
              </li>
              <li
                style={{
                  marginLeft: 25,
                  fontFamily: "LexendBold700",
                  fontSize: 50,
                  paddingTop: 10,
                  textAlign: "center",
                }}
              >
                ${totalRevenue.toFixed(2)}
              </li>
              <div style={{ width: "100%" }}>
                <li
                  style={{
                    marginLeft: 25,
                    fontFamily: "LexendMedium500",
                    fontSize: 22,
                    color: "#8f8d9f",
                    textAlign: "center",
                    paddingTop: 10,
                    paddingBottom: 50,
                  }}
                >
                  Total Sell
                </li>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 50,
              paddingBottom: 50,
            }}
          >
            <div style={{ width: 266, backgroundColor: "#DEE0F8" }}>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                }}
              >
                Monthly Subscriptions
              </li>
              <li
                style={{
                  marginTop: 70,
                  marginLeft: 20,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 44,
                }}
              >
                ${monthlyTotal.toFixed(2)}
              </li>
              <li
                style={{
                  marginLeft: 20,
                  fontSize: 14,
                  fontFamily: "LexendMedium500",
                  marginTop: 5,
                  color: "#75789E",
                }}
              >
                Total Revenue
              </li>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 70,
                  paddingBottom: 20,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                  color: "#75789E",
                }}
              >
                Total: <span style={{ color: "black" }}>{countMonthly}</span>
              </li>
            </div>
            <div style={{ width: 266, backgroundColor: "#E1EAED" }}>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                }}
              >
                Yearly Subscriptions
              </li>
              <li
                style={{
                  marginTop: 70,
                  marginLeft: 20,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 44,
                }}
              >
                ${yearlyTotal.toFixed(2)}
              </li>
              <li
                style={{
                  marginLeft: 20,
                  fontSize: 14,
                  fontFamily: "LexendMedium500",
                  marginTop: 5,
                  color: "#75789E",
                }}
              >
                Total Revenue
              </li>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 70,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                  color: "#75789E",
                }}
              >
                Total: <span style={{ color: "black" }}>{countYearly}</span>
              </li>
            </div>
            <div style={{ width: 266, backgroundColor: "#EDEBE1" }}>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 20,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                }}
              >
                Lifetime Subscriptions
              </li>
              <li
                style={{
                  marginTop: 70,
                  marginLeft: 20,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 44,
                }}
              >
                ${lifetimeTotal.toFixed(2)}
              </li>
              <li
                style={{
                  marginLeft: 20,
                  fontSize: 14,
                  fontFamily: "LexendMedium500",
                  marginTop: 5,
                  color: "#75789E",
                }}
              >
                Total Revenue
              </li>
              <li
                style={{
                  marginLeft: 20,
                  marginTop: 70,
                  paddingBottom: 20,
                  fontFamily: "LexendMedium500",
                  fontSize: 18,
                  color: "#75789E",
                }}
              >
                Total: <span style={{ color: "black" }}>{countLifetime}</span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
