import React, { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminGuard = (props) => {
  const userData = JSON.parse(localStorage.getItem("auth")) || null;

  if (userData && userData?.token && userData?.role !== "Users") {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default memo(AdminGuard);
