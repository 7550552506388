import { useEffect, useState } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/AllAdmins.css";
import searchIcon from "../Images/Programs/search.png";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";

export default function UsersList() {
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  var user = JSON.parse(localStorage.getItem("auth"));
  // const [allAdmins, setAllAdmins] = useState([]);
  const [allSuperadmins, setAllSuperadmins] = useState([]);
  // const [searchTimer, setSearchTimer] = useState(null);
  // const [searchAdminName, setSearchAdminName] = useState("");

  const [isDeleteEmail, setIsDeleteEmail] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");

  useEffect(() => {
    if (user) {
      setToken(user.token);
      setRole(user.role);
    }
  }, []);
  useEffect(() => {
    if (token) fetchAllSuperAdmins();
  }, [token]);

  function fetchAllSuperAdmins() {
    api
      .get("/accounts/get-all-superadmins", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllSuperadmins(response.data);
      });
  }
  return (
    <div className="allAdminsPage">
      <div style={{ display: "flex" }}>
        <LeftNavigationBar allAdmins={1} />
      </div>

      <div className="mainBody">
        <div
          style={{
            marginTop: 30,
            marginLeft: 30,
            marginRight: 30,
            display: "flex",
            justifyContent: "space-between",
          }}
        ></div>
        <div
          className=""
          style={{
            padding: "5px 30px 0px 30px",
          }}
        >
          <h3>Super Admin</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ fontFamily: "LexendRegular400" }}>ID</th>
              <th style={{ fontFamily: "LexendRegular400" }}>
                Superadmin Name
              </th>
              <th style={{ fontFamily: "LexendRegular400" }}>Role</th>
              <th style={{ fontFamily: "LexendRegular400" }}>Email</th>
            </tr>
          </thead>
          {allSuperadmins.map((val, key) => {
            return (
              <tbody key={key}>
                <tr className="adminRow">
                  <td>{key + 1}</td>
                  <td>{val.name}</td>
                  <td>Super Admin</td>
                  <td>{val.email}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div style={{ height: 5, marginBottom: 50 }} />
      </div>
    </div>
  );
}
