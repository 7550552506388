import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Compressor from "compressorjs";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Select from "react-select";
import "./Styles/Recipe.css";
import "./Styles/Tips.css";
import { toast } from "react-toastify";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function AddNewTip() {
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [key, setKey] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setThumbnail(newFile);
      setFileupdated(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setTempUploadedImageUrl(URL.createObjectURL(newFile));
      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
    setShowReactCropper(true);
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
    setThumbnail(null);
  };

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [recipeTitle, setRecipeTitle] = useState("");
  const [showReactCropper, setShowReactCropper] = useState(false);
  const [fileupdated, setFileupdated] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [tempUploadedPDFUrl, setTempUploadedPDFUrl] = useState(null);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const hiddenInputVideoUpload = useRef(null);

  const handleClickUploadVideo = (e) => {
    hiddenInputVideoUpload.current.click();
  };

  const handleChangeUploadedVideo = async (e) => {
    if (e.target.files.length > 0) {
      setTempUploadedPDFUrl("work");
      setPdfFile(e.target.files[0]);
      setIsLoadingPDF(true);

      const { Location } = await uploadImage(e.target.files[0]);
      setTempUploadedPDFUrl(Location);
      setIsLoadingPDF(false);
    }
    if (hiddenInputVideoUpload.current) {
      hiddenInputVideoUpload.current.value = "";
    }
  };

  const cropperRef = createRef();

  const handleRemovePDF = () => {
    setPdfFile(null);
    setTempUploadedPDFUrl(null);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
  }, []);

  const handleSelectCategory = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get("/tips/get-category-list", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllCategories(response.data);
      });
  }, []);

  useEffect(() => {
    if (showReactCropper) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showReactCropper]);

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setTempUploadedImageUrl("");
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 300);
        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        setTempUploadedImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const handleCreateTip = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (file === null && pdfFile === null) {
      await api
        .post(
          "/tips/create-tip",
          {
            Title: recipeTitle,
            CategoryList: selectedValue,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Tip Created Successfully", {
            position: "top-right",
            autoClose: 2000,
            theme: "dark",
          });
          navigate("/tips");
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else if (file != null && pdfFile == null) {
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);

      await api
        .post(
          "/tips/create-tip",
          {
            Title: recipeTitle,
            CategoryList: selectedValue,
            ImageUrl: imageResponse.Location,
            ThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Tip Created Successfully", {
            position: "top-right",
            autoClose: 2000,
            theme: "dark",
          });
          navigate("/tips");
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);

      await api
        .post(
          "/tips/create-tip",
          {
            Title: recipeTitle,
            CategoryList: selectedValue,
            ImageUrl: imageResponse.Location,
            PdfUrl: tempUploadedPDFUrl,
            ThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          toast.success("Tip Created Successfully", {
            position: "top-right",
            autoClose: 2000,
            theme: "dark",
          });
          navigate("/tips");
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };
  var data = [];
  for (var i = 0; i < allCategories.length; i++) {
    data.push({
      value: allCategories[i].id,
      label: allCategories[i].name,
    });
  }

  return (
    <div className="addNewProgramPage">
      <div style={{ display: "flex" }}>
        <LeftNavigationBar tips={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate("/tips")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Add New Tip
          </li>
          <input
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <form
            onSubmit={handleCreateTip}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              {showReactCropper && (
                <div id="modal10" className="cropper-modal-wrapper">
                  <Cropper
                    ref={cropperRef}
                    zoomTo={0}
                    initialAspectRatio={16 / 11}
                    aspectRatio={16 / 11}
                    preview=".img-preview"
                    src={tempUploadedImageUrl}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    className="croppper-body"
                  />
                  <button
                    onClick={cancleCropperModal}
                    className="cropper-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="cropper-submit" onClick={getCropData}>
                    Save
                  </button>
                </div>
              )}
              {tempUploadedImageUrl.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    key={key}
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the tip
                  </li>
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 200,
                        height: 200,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="Title"
                  value={recipeTitle}
                  onChange={(e) => setRecipeTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <Select
                  className="dropdown-tips"
                  placeholder="Select Categories"
                  value={data.filter((obj) =>
                    selectedValue.includes(obj.value)
                  )} // set selected values
                  options={data} // set list of the data
                  onChange={handleSelectCategory} // assign onChange function
                  isMulti
                  isClearable
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="submit"
                  >
                    Create
                  </button>
                ) : (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="button"
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the tip!
                  </li>
                )}
              </div>
            </div>
            <br />
            <div>
              <input
                type="file"
                accept="application/pdf"
                ref={hiddenInputVideoUpload}
                onChange={handleChangeUploadedVideo}
                style={{ display: "none" }}
              />
              {tempUploadedPDFUrl === null ? (
                <div
                  onClick={handleClickUploadVideo}
                  style={{
                    cursor: "pointer",
                    width: 200,
                    marginTop: 10,
                    background: "#ffffff",
                    padding: "1px 5px",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      textAlign: "center",
                      marginTop: 25,
                    }}
                  >
                    Upload PDF
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    *Only pdf format is supported
                  </li>
                </div>
              ) : (
                <div style={{ borderStyle: "none", width: "100%" }}>
                  {pdfFile ? (
                    isLoadingPDF ? (
                      <div
                        style={{
                          marginTop: 25,
                          width: 200,
                          height: 200,
                        }}
                      >
                        <Spinner
                          style={{
                            color: "black",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 250,
                          backgroundColor: "#ffffff",
                          padding: 10,
                          marginTop: 10,
                        }}
                      >
                        <p>{pdfFile.name}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: 250,
                            alignItems: "center",
                          }}
                        >
                          <button
                            style={{
                              display: "block",
                              marginTop: 10,
                              fontFamily: "LexendRegular400",
                              fontSize: 14,
                            }}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              href={tempUploadedPDFUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              View PDF
                            </a>
                          </button>
                          <button
                            onClick={handleRemovePDF}
                            style={{
                              display: "block",
                              marginTop: 10,
                              fontFamily: "LexendRegular400",
                              fontSize: 14,
                            }}
                          >
                            Remove PDF
                          </button>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
