import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../Contexts/BaseUrl';
import './Styles/ResetPassword.css';
//import getUserDetails from './fetch/getUserDetails';

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(null);
    const [err, setErrMsg] = useState("")

    // const user = JSON.parse(JSON.stringify(data));
    const email = searchParams.get('email');
    const token = searchParams.get('token');


    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await api.post(
                '/accounts/reset-password',
                {
                    password: password,
                    confirmPassword: confirmPassword,
                    token: token,
                    email: email
                },
                {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    }
                });

            setPassword("");
            setConfirmPassword("");
            setSuccess(true);
            setIsLoading(false);

        } catch (err) {

            setPassword("");
            setConfirmPassword("");
            setSuccess(false);
            setIsLoading(false);
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Failed changing password!');
            }
        }

    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await api.post('/accounts/reset-password', {
            password: password,
            confirmPassword: confirmPassword,
            email: email,
            token: token
        }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json-patch+json"
            }
        }).then(response => {
            setSuccess(true);
            setIsLoading(false);
            setPassword('');
            setConfirmPassword('');
        }).catch(err => {
            console.log(err.response);
            setSuccess(false);
            setIsLoading(false);
            setPassword('');
            setConfirmPassword('');
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else {
                setErrMsg('Failed changing password!');
            }
        })
    }


    return (

        <div style={{ width: '100%', background: '#FAFAFD' }}>
            {!success &&
                <form className="resetForm"
                    onSubmit={handleChangePassword}
                >

                    <div style={{ fontWeight: 'bold', color: 'white' }}>Email : {email}</div>
                    <label>New Password: </label>
                    <input
                        type="password"
                        value={password}
                        required
                        onChange={event => {
                            setPassword(event.target.value);

                            if (event.target?.validity?.patternMismatch) {
                                event.target?.setCustomValidity('Min - 8 chars,1 Uppercase, 1 Lowercase, 1 number, 1 special character ');
                            } else {
                                event.target?.setCustomValidity('');
                            }
                        }}
                    />
                    <label>Confirm New Password: </label>
                    <input
                        type="password"
                        value={confirmPassword}
                        required
                        onChange={event => {
                            setConfirmPassword(event.target.value);

                            if (event.target?.value !== password) {
                                event.target?.setCustomValidity('Password doesn\'t match');
                            } else {
                                event.target?.setCustomValidity('');
                            }
                        }}
                    />
                    {isLoading ?
                        <button className='userButton'>Loading...</button>
                        :
                        <button className='userButton'>Save</button>
                    }

                    {!success && <div className='error'>{err}</div>}
                    {success && <div className='success'>Password Successfully Changed!</div>}
                </form>
            }
            {
                success &&
                <div style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold' }}>
                    Password has been reset successful. Please Log In.
                </div>
            }

        </div>

    );
}

export default ResetPassword;