export default function PrivacyPolicy(){
    return(
        <div style={{ width: '100%', background: 'white', fontFamily: 'LexendRegular400' }}>
            <div style={{ width: '75%', display: 'block', margin: 'auto', paddingTop: 20 }}>
                <h1>Privacy Policy for Muscle Sisters Fitness App</h1>
                <h3>Effective Date: July 5th, 2023</h3>
                <p>
                    This Privacy Policy outlines how Muscle Sisters Fitness ("we," "us," or "our") collects, uses, shares, and protects the 
                    personal information of users ("you" or "your") when you use the Muscle Sisters Fitness mobile application 
                    ("the App"). Your privacy is of utmost importance to us, and we are committed to safeguarding your 
                    personal information. By using the App, you consent to the collection and use of your information 
                    as described in this Privacy Policy.
                </p>

                <h3>Information We Collect</h3>
                <p>
                1.1 Personal Information: We may collect personal information you provide to us voluntarily, including but not limited to your name, email address, gender, date of birth, weight, height, fitness goals, and workout preferences when you register for an account or use the App's features.
                </p>
                <p>
                1.2 Usage Information: We automatically collect certain information about your use of the App, including your device information, IP address, operating system, app version, usage logs, and browsing activity within the App.
                </p>
                <p>
                1.3 Payment Information: If you choose to make purchases within the App, we may collect payment information such as credit card details or other payment credentials. However, please note that we do not store or process your payment information directly. It is securely processed by our third-party payment processor.
                </p>
                <p>
                1.4 Social Media Integration: If you choose to link your social media accounts with the App, we may collect certain information from those accounts in accordance with your privacy settings on those platforms.
                </p>
                <h3>How We Use Your Information</h3>
                <p>
                2.1 Providing and Personalizing Services: We use your information to provide, personalize, and improve the App's features and functionalities, including tailoring workout plans, tracking progress, sending notifications, and providing customer support
                </p>
                <p>
                2.2 Communication: We may use your email address to send you service-related communications, updates, promotional offers, or other relevant information. You can opt-out of receiving marketing emails by following the unsubscribe instructions in the email.
                </p>
                <p>
                2.3 Analytics and Research: We may analyze usage patterns and trends to improve the App, develop new features, and conduct research to enhance our services. However, we only use aggregated and anonymized data for these purposes.
                </p>
                <p>
                2.4 Legal Compliance: We may use your information to comply with applicable laws, regulations, or legal processes, and to protect our rights, privacy, safety, or property, or that of our users or the public.
                </p>
                <h3>Information Sharing and Disclosure</h3>
                <p>
                3.1 Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating the App, such as hosting providers, payment processors, analytics tools, and customer support platforms. These providers are bound by confidentiality obligations and are only authorized to use your information as necessary to provide services to us.
                </p>
                <p>
                3.2 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity as part of the transaction. We will notify you of any such change in ownership or control of your personal information.
                </p>
                <p>
                3.3 Legal Requirements: We may disclose your information if required to do so by law or in response to valid legal requests or legal processes, such as court orders or subpoenas.
                </p>    
                <h3>Data Security</h3>
                <p>
                We implement reasonable technical and organizational measures to protect your personal information from unauthorized access, loss, misuse, alteration, or disclosure. However, no data transmission over the internet or electronic storage method is entirely secure, and we cannot guarantee absolute security.
                </p>
                <h3>Data Retention</h3>
                <p>
                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We will securely delete or anonymize your personal information
                </p>
            </div>

        </div>
    )
}