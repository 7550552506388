import "./Styles/UserDetails.css";
import { useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

export default function UserDetails() {
  let params = useParams();
  var userId = params.userId;

  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const [userName, setUserName] = useState("");
  const [packageId, setPackageId] = useState(0);

  const [packages, setPackages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    api
      .get(`/accounts/get-account-holder?email=${userId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setUserName(response.data.name);
        setPackageId(response.data.packageSubscribedId);
      });

    api
      .get(`/subscriptionpackages/get-all-packages`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setPackages(response.data);
      });
  }, []);

  const fetchUserDetails = () => {
    api
      .get(`/accounts/get-account-holder?email=${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserName(response.data.name);
      });
  };

  const handleUpdateUserDetails = async (e) => {
    e.preventDefault();
    await api
      .post(
        "/accounts/edit-user-details",
        {
          email: userId,
          name: userName,
          packageSubscribedId: packageId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsSuccessful(true);
        navigate(-1);
      });
  };

  return (
    <div className="userDetailsPage">
      <LeftNavigationBar usersList={1} />
      <form className="mainBody" onSubmit={handleUpdateUserDetails}>
        <div>
          <div style={{ height: 50 }} />
          <div
            onClick={() => navigate("/users-list")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>

          <li
            style={{
              marginBottom: 20,
              fontSize: 24,
              fontFamily: "LexendBold700",
            }}
          >
            User Details
          </li>
          <input
            type="text"
            required
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Name"
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              width: 300,
              fontSize: 14,
              fontFamily: "LexendRegular400",
            }}
          />
          <br />

          <div
            style={{
              background: "#FFF2F2",
              marginBottom: 20,
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              width: 300,
              height: 20,
              fontSize: 14,
              fontFamily: "LexendRegular400",
            }}
          >
            <li style={{ fontSize: 14, fontFamily: "LexendRegular400" }}>
              {userId}
            </li>
          </div>
          <br />

          <li
            style={{
              marginTop: 30,
              marginBottom: 20,
              fontSize: 24,
              fontFamily: "LexendBold700",
            }}
          >
            Active Subscriptions
          </li>
          <div>
            {packages.map((val, key) => {
              return (
                <div
                  onClick={() => setPackageId(val.subscriptionPackageId)}
                  key={key}
                  style={{
                    cursor: "pointer",
                    borderStyle: "none",
                    width: 320,
                    marginBottom: 20,
                    borderRadius: 15,
                    background:
                      val.subscriptionPackageId === packageId
                        ? "#F38CAC"
                        : "#ffffff",
                    color:
                      val.subscriptionPackageId === packageId
                        ? "#ffffff"
                        : "black",
                  }}
                >
                  <li
                    style={{
                      paddingLeft: 20,
                      paddingTop: 20,
                      paddingBottom: 10,
                      fontFamily: "LexendMedium500",
                      fontSize: 14,
                    }}
                  >
                    {val.subscriptionPackageName}
                  </li>
                  <li
                    style={{
                      paddingLeft: 20,
                      paddingTop: 0,
                      paddingBottom: 20,
                      fontFamily: "LexendExtraBold800",
                      fontSize: 18,
                    }}
                  >
                    ${val.subscriptionPackagePrice} /{" "}
                    {val.subscriptionPackageUnit}
                  </li>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ width: "40%", height: "100%" }}>
          {/* <div style={{ width: 120, height: 120, borderRadius: 70, borderStyle: 'solid', marginTop: 130, marginLeft: 'auto', marginRight: 'auto', marginBottom: 40, backgroundColor: '#FFF2F2'  }} /> */}
          <button
            style={{
              width: 140,
              height: 40,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "#FFF2F2",
              fontFamily: "LexendBold700",
              fontSize: 18,
              color: "#FF0000",
              borderStyle: "none",
              marginTop: 130,
            }}
          >
            Block
          </button>
          <br />
          <button
            style={{
              width: 140,
              height: 40,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "#4176FF",
              fontFamily: "LexendBold700",
              fontSize: 18,
              color: "#ffffff",
              borderStyle: "none",
            }}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
