import { useRef, useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/Contact.css";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import api from "../Contexts/BaseUrl";

export default function Equipments() {
  const [token, setToken] = useState(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  var user = JSON.parse(localStorage.getItem("auth"));
  const [isLoading, setIsLoading] = useState(false);
  const [isEditContact, setIsEditContact] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      setPhoneError("Phone number is required");
      return false;
    }
    // Use a regular expression or other validation logic to check the phone number.

    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneError("Phone number must be 10 digits");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    // Regular expression for a valid email address
    const emailRegex =
      /^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z.-]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email) || email !== email.toLowerCase()) {
      setEmailError("Invalid email address");
      return false;
    }

    setEmailError("");
    return true;
  };

  useEffect(() => {
    if (user) {
      setToken(user.token);
    }
  }, []);
  useEffect(() => {
    if (token) fetchContact();
  }, [token]);

  const fetchContact = () => {
    api
      .get(`/contacts/get-individual-contact`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setContactId(response.data.contactId);
        setEmail(response.data.contactEmail);
        setPhone(response.data.contactPhoneNumber);
        setLocation(response.data.contactOfficeLocation);
      });
  };

  const handleUpdateContact = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phone) || !validateEmail(email)) {
      return;
    }
    setIsLoading(true);
    await api
      .post(
        `/Contacts/${contactId > 0 ? "edit-contact" : "create-contact"}`,
        {
          contactId: contactId > 0 ? contactId : undefined,
          contactEmail: email,
          contactPhoneNumber: phone,
          contactOfficeLocation: location,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsUpdated(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsEditContact(false);
        setIsLoading(false);
      });
  };

  const handleBack = () => {
    fetchContact();
    setIsEditContact(false);
    setIsUpdated(false);
  };

  return (
    <div className="contactPage" style={{ height: windowSize.current[1] }}>
      <LeftNavigationBar contact={1} />

      {isUpdated && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Contact Details has been
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              updated successfully!
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={handleBack}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                  borderRadius: 4,
                  display: "block",
                  marginLeft: 40,
                  marginTop: 10,
                }}
              >
                Back
              </button>
            </div>
          </div>
        </>
      )}
      <div className="mainBody">
        <div
          style={{
            marginTop: 40,
            display: "flex",
            justifyContent: "space-between",
            width: 720,
          }}
        >
          {!isEditContact ? (
            <>
              <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
                Contact Us
              </li>
              <button
                onClick={() => setIsEditContact(true)}
                style={{
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  paddingTop: 5,
                  paddingBottom: 5,
                  width: 100,
                  borderStyle: "none",
                  background: "#FFF2F2",
                  color: "#FF0000",
                }}
              >
                Edit
              </button>
            </>
          ) : (
            <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
              Edit Contact Us
            </li>
          )}
        </div>
        <div style={{ marginTop: 20, height: 300, width: 720 }}>
          {!isEditContact && (
            <>
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 20,
                  paddingBottom: 10,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 150,
                  }}
                >
                  Email:
                </li>
                <li style={{ fontFamily: "LexendRegular400", fontSize: 18 }}>
                  {email}
                </li>
              </div>
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 10,
                  paddingBottom: 10,
                  marginTop: 0,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 150,
                  }}
                >
                  Phone:
                </li>
                <li style={{ fontFamily: "LexendRegular400", fontSize: 18 }}>
                  {phone}
                </li>
              </div>
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 10,
                  paddingBottom: 20,
                  marginTop: 0,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 150,
                  }}
                >
                  Office:
                </li>
                <li style={{ fontFamily: "LexendRegular400", fontSize: 18 }}>
                  {location}
                </li>
              </div>
            </>
          )}
          {isEditContact && (
            <div
              style={{
                background: "#ffffff",
                paddingBottom: 30,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 20,
                  paddingBottom: 10,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 100,
                  }}
                >
                  Email:
                </li>
                <input
                  placeholder="Enter contact email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                    fontFamily: "LexendMedium500",
                    fontSize: 14,
                  }}
                />
              </div>
              {emailError && (
                <div style={{ color: "red", marginLeft: 120 }}>
                  {emailError}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 10,
                  paddingBottom: 10,
                  marginTop: 0,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 100,
                  }}
                >
                  Phone:
                </li>
                <input
                  placeholder="Enter contact phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                    fontFamily: "LexendMedium500",
                    fontSize: 14,
                  }}
                />
              </div>
              {phoneError && (
                <div style={{ color: "red", marginLeft: 120 }}>
                  {phoneError}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  background: "#ffffff",
                  paddingTop: 10,
                  paddingBottom: 20,
                  marginTop: 0,
                }}
              >
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginLeft: 20,
                    width: 100,
                  }}
                >
                  Office:
                </li>
                <input
                  placeholder="Enter office location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 180,
                    fontFamily: "LexendMedium500",
                    fontSize: 14,
                  }}
                />
              </div>
              {!isLoading ? (
                <button
                  onClick={handleUpdateContact}
                  style={{
                    height: 35,
                    borderStyle: "none",
                    background: "#F38CAC",
                    color: "#ffffff",
                    marginTop: 10,
                    width: 120,
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    paddingTop: 5,
                    paddingBottom: 5,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  style={{
                    borderStyle: "none",
                    background: "#F38CAC",
                    color: "#ffffff",
                    marginTop: 10,
                    width: 120,
                    height: 35,
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    paddingTop: 5,
                    paddingBottom: 5,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Spinner
                    size={12}
                    style={{
                      color: "#ffffff",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                </button>
              )}
              <button
                onClick={() => setIsEditContact(false)}
                style={{
                  height: 35,
                  borderStyle: "none",
                  background: "#FFF2F2",
                  color: "#FF0000",
                  width: 120,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  paddingTop: 5,
                  paddingBottom: 5,
                  display: "block",
                  marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
