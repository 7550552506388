import "./Styles/Login.css";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

export default function Login() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Wrong Email or Password!");
  const [isForgotPassword, setIsForgotPassword] = useState(false);


  const refresh = localStorage.getItem("refresh");
  if (refresh != null) {
    localStorage.removeItem("refresh");
    window.location.reload();
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await api
      .post("/accounts/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("auth", JSON.stringify(response.data));
        // setEmail("");
        // setPassword("");
        setIsLoading(false);
        navigate("/home");
        // localStorage.setItem("refresh", 1);
      })
      .catch((err) => {
        var code = err.response.status;
        console.log(code);
        if (code === 401) {
          setErrorMsg("Invalid Login Credentials!");
        } else if (code === 403) {
          setErrorMsg("Please confirm your Phone Number!");
        } else {
          setErrorMsg("Login Failed! Try again later");
        }
        setIsLoading(false);
        setIsError(true);
      });
  };

  function handleForgotPassword() {
    navigate('/forget-password')
  }

  return (
    <>{isForgotPassword &&
      <>
        <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3, zIndex: 4 }} />
        <div id='modal2'>
          <li style={{ textAlign: 'center' }}>Password reset link has</li>
          <li style={{ textAlign: 'center' }}>been sent to your email</li>
          <button onClick={() => setIsForgotPassword(false)} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 80, marginTop: 20, borderStyle: 'none', height: 35, fontFamily: 'LexendSemiBold600', fontSize: 18, backgroundColor: '#F38CAC', color: 'white' }}>Ok</button>
        </div>
      </>
    }
      <div className="loginPage" style={{ height: windowSize.current[1] }}>
        <div id="leftSide" style={{ height: windowSize.current[1] }}>
          <div>
            <h4 style={{ fontFamily: "LexendBold700", fontSize: 34 }}>
              {" "}
              Muscle Sisters{" "}
            </h4>
            <div style={{ position: "absolute", bottom: 10 }}>
              <span
                onClick={() => navigate("/privacy-policy")}
                style={{ cursor: "pointer", margin: 10 }}
              >
                Privacy Policy
              </span>
              <span
                onClick={() => navigate("/terms")}
                style={{ cursor: "pointer" }}
              >
                Terms & Conditions
              </span>
            </div>
          </div>
        </div>
        <div id="rightSide">
          <div id="loginBox">
            <form id="loginForm" onSubmit={handleLogin}>
              <li style={{ fontFamily: "LexindMedium500", fontSize: 28 }}>
                Log In Your Account
              </li>
              {isError && (
                <li
                  style={{
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                    color: "red",
                    marginTop: 5,
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </li>
              )}
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                style={{
                  width: "95%",
                  paddingLeft: 20,
                  paddingTop: 15,
                  paddingBottom: 15,
                  marginTop: 20,
                  backgroundColor: "#FAFAFD",
                  borderStyle: "none",
                  borderRadius: 9,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                }}
              />
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                style={{
                  width: "95%",
                  paddingLeft: 20,
                  paddingTop: 15,
                  paddingBottom: 15,
                  marginTop: 20,
                  backgroundColor: "#FAFAFD",
                  borderStyle: "none",
                  borderRadius: 9,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                }}
              />

              {!isLoading ? (
                <button type="submit">Log In</button>
              ) : (
                <button>
                  <Spinner
                    size={15}
                    color="white"
                    style={{ diplay: "block", margin: "auto" }}
                  />
                </button>
              )}

              <button type="button" onClick={() => handleForgotPassword()} style={{ backgroundColor: '#EAF0FF', color: '#FF0000', }} id='buttonUserProfile'>Forgot Password</button>
            </form>
          </div>
        </div>
      </div>.
    </>
  );
}
