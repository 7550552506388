import { useEffect, useState, useRef } from 'react';
import LeftNavigationBar from '../Components/LeftNavigationBar';
import './Styles/UsersList.css';
import searchIcon from '../Images/Programs/search.png';
import { useNavigate } from 'react-router-dom';
import api from '../Contexts/BaseUrl';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

export default function UsersList() {

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const navigate = useNavigate();
    const [token, setToken] = useState(null);


    //block user starts
    const [isBlock, setIsBlock] = useState(false);
    const [blockEmail, setBlockEmail] = useState('');
    const [blockName, setBlockName] = useState('');
    const [allBlockedUsers, setAllBlockedUsers] = useState([]);

    function handleBlockInitial(email, name) {
        setIsBlock(true);
        setBlockEmail(email);
        setBlockName(name);
    }

    const handleBlockFinal = async (e) => {
        e.preventDefault();
        await api.post('/blockedUsers/create-blocked-user', {
            blockedUserEmail: blockEmail,
            blockedUserName: blockName
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setIsBlock(false);
            fetchAllUsers();
        }).catch(err => {
            setIsBlock(false);
        })
    }

    //block user ends

    //unblock starts
    const [isUnblock, setIsUnblock] = useState(false);
    const [unblockUserEmail, setUnblockUserEmail] = useState('');

    function handleUnblockUserInitial(email) {
        setUnblockUserEmail(email);
        setIsUnblock(true);
    }

    const handleUnblockUserFinal = async (e) => {
        e.preventDefault();
        await api.post('/blockedUsers/delete-blocked-user', {
            blockedUserEmail: unblockUserEmail
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            fetchAllUsers();
            setIsUnblock(false);
        }).catch(err => {
            setIsUnblock(false);
        })
    }
    //unblock ends

    //user list pagination starts
    const [allUsers, setAllUsers] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(1);

    const [searchInput, setSearchInput] = useState('');

    const pageNext = () => {
        if (pagination.totalPages > pagination.currentPage) {
            setPage(page + 1);
        }
    }

    const pagePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const pageChangeInput = (e) => {
        if (e.target.value > pagination.totalPages) {
            setPage(pagination.totalPages);
        } else {
            setPage(e.target.value);
        }
    }

    var urlParams;

    if (searchInput.length > 0) {
        urlParams = new URLSearchParams({
            name: searchInput,
        }).toString();
    } else {
        urlParams = new URLSearchParams({
            pageNumber: page,
            pageSize: 20

        }).toString();
    }

    var url = `/accounts/get-all-app-users?` + urlParams;
    //user list pagination ends

    useEffect(() => {
        var user = JSON.parse(localStorage.getItem('auth'));
        if (user) {
            setToken(user.token);
        }

        api.get(url, {
            headers: { Authorization: `Bearer ${user.token}` }
        }).then(response => {
            console.log(response)
            setAllUsers(response.data);
            setPagination(JSON.parse(response.headers.pagination));
        })

        api.get(`/blockedUsers/get-all-blocked-users`, {
            headers: { Authorization: `Bearer ${user.token}` }
        }).then(response => {
            var user = [];
            for (var i = 0; i < response.data.length; i++) {
                user.push(response.data[i].blockedUserEmail)
            }
            setAllBlockedUsers(user);
        })

    }, [url])

    function fetchAllUsers() {
        api.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setAllUsers(response.data);
            setPagination(JSON.parse(response.headers.pagination));
        })

        api.get(`/blockedUsers/get-all-blocked-users`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            var user = [];
            for (var i = 0; i < response.data.length; i++) {
                user.push(response.data[i].blockedUserEmail)
            }
            setAllBlockedUsers(user);
        })
    }

    const records = 20
    return (
        <div className='usersListPage' >
            {isBlock &&
                <>
                    <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3, zIndex: 4 }} />
                    <div id='modal'>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginTop: 35 }}>Are you sure you want to Block</li>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginBottom: 20 }}>this user?</li>
                        <div style={{ marginLeft: 100 }}>
                            <button onClick={handleBlockFinal} style={{ cursor: 'pointer', backgroundColor: '#ffba26', color: '#0a2e28', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>Yes</button>
                            <button onClick={() => setIsBlock(false)} style={{ cursor: 'pointer', backgroundColor: '#e63946', color: 'white', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>No</button>
                        </div>
                    </div>
                </>
            }
            {isUnblock &&
                <>
                    <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3, zIndex: 4 }} />
                    <div id='modal'>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginTop: 35 }}>Are you sure you want to Unblock</li>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginBottom: 20 }}>this user?</li>
                        <div style={{ marginLeft: 100 }}>
                            <button onClick={handleUnblockUserFinal} style={{ cursor: 'pointer', backgroundColor: '#ffba26', color: '#0a2e28', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>Yes</button>
                            <button onClick={() => setIsUnblock(false)} style={{ cursor: 'pointer', backgroundColor: '#e63946', color: 'white', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>No</button>
                        </div>
                    </div>
                </>
            }
            <div style={{ display: 'flex', width: '100%' }}>
                <LeftNavigationBar usersList={1} />

                {/* <div style={{ width: windowSize.current[0], marginLeft: 300, display: 'flex', justifyContent: 'center' }}> */}
                <div className='mainBody'>
                    <div style={{ marginTop: 30, marginLeft: 30, marginRight: 0, display: 'flex', gap:'20px' }}>
                        <img src={searchIcon} alt='searchIcon' style={{ width: 25, height: 25, position: 'absolute', marginLeft: 20, marginTop: 12 }} />
                        <input
                            type='search'
                            placeholder='Search by User Name'
                            onChange={e => setSearchInput(e.target.value.toLowerCase())}
                            value={searchInput}
                            style={{
                                // marginLeft: 30, 
                                width: '620px',
                                height: 50,
                                paddingLeft: 60,
                                paddingRight: 20,
                                background: '#F5F5F5',
                                borderStyle: 'none',
                                fontFamily: 'LexendRegular400',
                                fontSize: 14
                            }}
                        />

                        <button onClick={() => navigate('/blocked-user')} style={{ cursor: 'pointer', borderStyle: 'none', height: 50, backgroundColor: '#FFF2F2', fontFamily: 'LexendSemiBold600', fontSize: 18, color: '#FF0000', width: '200px' }}>
                            Blocked Users
                        </button>
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <th style={{ fontFamily: 'LexendRegular400' }}>ID</th>
                            <th style={{ fontFamily: 'LexendRegular400' }}>User Name</th>
                            <th style={{ fontFamily: 'LexendRegular400' }}>Email / Phone</th>
                            <th style={{ fontFamily: 'LexendRegular400' }}>Subscription</th>
                            <th style={{ fontFamily: 'LexendRegular400' }}>Actions</th>
                        </tr>
                        </tbody>
                        {allUsers.map((val, key) => {
                            const calculatedIndex = (page - 1) * records + key + 1;
                            return (
<tbody key={key}>
                                <tr >
                                    <td>{calculatedIndex}</td>
                                    <td>{val.name}</td>
                                    <td>{val.email}</td>
                                    {val.packageSubscribedId === 4 && <td>Free Trial</td>}
                                    {val.packageSubscribedId === 1 && <td>Monthly</td>}
                                    {val.packageSubscribedId === 2 && <td>Annual</td>}
                                    {val.packageSubscribedId === 3 && <td>Lifetime</td>}
                                    <td>
                                        <button onClick={() => navigate(`/user-details/${val.email}`)} style={{ backgroundColor: 'white', width: 100, paddingTop: 5, paddingBottom: 5, borderStyle: 'none', fontFamily: 'LexendMedium500' }}>Edit</button> <br />

                                        {allBlockedUsers.includes(val.email) ?

                                            <button onClick={() => handleUnblockUserInitial(val.email, val.name)} style={{ marginTop: 10, color: '#FF0000', backgroundColor: '#FFF2F2', width: 100, paddingTop: 5, paddingBottom: 5, borderStyle: 'none', fontFamily: 'LexendMedium500' }}>Unblock</button>
                                            :
                                            <button onClick={() => handleBlockInitial(val.email, val.name)} style={{ marginTop: 10, color: '#FF0000', backgroundColor: '#FFF2F2', width: 100, paddingTop: 5, paddingBottom: 5, borderStyle: 'none', fontFamily: 'LexendMedium500' }}>Block</button>

                                        }

                                    </td>
                                </tr>
                                </tbody>
                            )
                        })}
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                        <button onClick={pagePrevious} style={{ cursor: 'pointer', width: 35, height: 30, borderWidth: 0, backgroundColor: '#0a2e28' }}>
                            <FiChevronLeft size={24} color='white' style={{ marginTop: 2, marginLeft: -2 }} />
                        </button>
                        <input style={{ fontFamily: 'LexendMedium500', fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 20, width: 30, textAlign: 'center', height: 25, borderWidth: 1, borderColor: '#999999', borderStyle: 'solid' }}
                            value={page}
                            onChange={pageChangeInput}
                        />
                        <div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>/</div>
                        <div style={{ fontFamily: 'LexendMedium500', fontSize: 14 }}>{pagination.totalPages}</div>
                        <button onClick={pageNext} style={{ cursor: 'pointer', marginLeft: 20, width: 35, height: 30, borderWidth: 0, backgroundColor: '#0a2e28' }}>
                            <FiChevronRight size={24} color='white' style={{ marginTop: 2 }} />
                        </button>

                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}