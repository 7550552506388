import { useEffect, useState} from 'react';
import LeftNavigationBar from '../Components/LeftNavigationBar';
import './Styles/BlockedUser.css';
import searchIcon from '../Images/Programs/search.png';
import { useNavigate } from 'react-router-dom';
import api from '../Contexts/BaseUrl';
import backIcon from '../Images/AddAdmin/back.png';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

export default function BlockedUser(){

    const navigate = useNavigate(); 
    const [token, setToken] = useState(null);
    //blocked users pagination starts
    const [allBlockedUsers, setAllBlockedUsers] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(1);

    const [searchInput, setSearchInput] = useState('');

    const [isUnblock, setIsUnblock] = useState(false);
    const [unblockUserEmail, setUnblockUserEmail] = useState('');

    function handleUnblockUserInitial(email){
        setUnblockUserEmail(email);
        setIsUnblock(true);
    }

    const handleUnblockUserFinal = async (e) => {
        e.preventDefault();
        await api.post('/blockedUsers/delete-blocked-user',{
            blockedUserEmail: unblockUserEmail
        },{
            headers: {Authorization: `Bearer ${token}`}
        }).then(response => {
            fetchAllBlockedUsers();
            setIsUnblock(false);
        }).catch(err => {
            setIsUnblock(false);
        })
    }

    const pageNext = () => {
        if(pagination.totalPages > pagination.currentPage){
            setPage(page+1);
        }
    }

    const pagePrevious = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    const pageChangeInput = (e) => {
        if(e.target.value > pagination.totalPages){
            setPage(pagination.totalPages);
        }else{
            setPage(e.target.value);
        }
    }

    var urlParams;

    if(searchInput.length > 0){
        urlParams = new URLSearchParams({
            userName: searchInput,
        }).toString();
    }else{
        urlParams = new URLSearchParams({
            pageNumber: page ,
            pageSize: 20
        }).toString();
    }


    var url = `/blockedUsers/get-all-blocked-users?` + urlParams;
    //blocked users pagination ends


    useEffect(() => {
        var user = JSON.parse(localStorage.getItem('auth'));
        if(user){
            setToken(user.token);
        }
        
        api.get(url, {
          headers: {Authorization: `Bearer ${user.token}`}  
        }).then(response => {
            setAllBlockedUsers(response.data);
            setPagination(JSON.parse(response.headers.pagination));
        }) 

    }, [url])

    function fetchAllBlockedUsers(){
        api.get(url, {
            headers: {Authorization: `Bearer ${token}`}  
          }).then(response => {
              setAllBlockedUsers(response.data);
              setPagination(JSON.parse(response.headers.pagination));
          }) 
    }

    return(
        <div className='blockedUserPage' >
            {isUnblock &&
         <>
            <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3, zIndex: 4 }} />
            <div id='modal'>
                <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginTop: 35 }}>Are you sure you want to Unblock</li>
                <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500',marginBottom: 20  }}>this user?</li>
                <div style={{ marginLeft: 100 }}>
                    <button onClick={handleUnblockUserFinal} style={{cursor: 'pointer', backgroundColor: '#ffba26', color: '#0a2e28', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4  }}>Yes</button>
                    <button onClick={() => setIsUnblock(false)} style={{cursor: 'pointer', backgroundColor: '#e63946', color: 'white', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4  }}>No</button>
                </div>
            </div>
         </>
         } 

            <div style={{ display: 'flex', width: '100%' }}>
            <LeftNavigationBar usersList={1} />
            <div className='mainBody'>
                <div onClick={() => navigate(-1)} style={{marginLeft: 0,cursor: 'pointer', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', width: 40, height: 40, borderRadius: 25, borderStyle: 'solid', borderColor: '#F38CAC', borderWidth: 1 }}>
                    <img src={backIcon} alt='backicon' style={{ maxHeight: 12 }} />
                </div>
            <li style={{ color: 'black', marginLeft: 0, marginBottom: 20, fontFamily: 'LexendBold700', fontSize: 24 }}>Blocked Users</li>
                <div style={{ background: '#ffffff', paddingTop: 10, paddingBottom: 10, marginBottom: 100 }}>
                <div style={{marginTop: 30, marginLeft: 30, marginRight: 30, display: 'flex', justifyContent: 'space-between', background: '#ffffff'}}>
                        <img src={searchIcon} alt='searchIcon' style={{ width: 25, height: 25, position: 'absolute', marginLeft: 20, marginTop: 12 }} />
                        <input
                            type='search'
                            placeholder='Search by User Name'
                            onChange={e => setSearchInput(e.target.value.toLowerCase())}
                            value={searchInput}
                            style={{ 
                                // marginLeft: 30, 
                                width: '60%', 
                                height: 50, 
                                paddingLeft: 60, 
                                paddingRight: 20,
                                background: '#F5F5F5', 
                                borderStyle: 'none',
                                fontFamily: 'LexendRegular400',
                                fontSize: 14
                            }}
                        />
                          
                        
                </div>
                <table>
                    <tr>
                        <th style={{ fontFamily: 'LexendRegular400' }}>ID</th>
                        <th style={{ fontFamily: 'LexendRegular400' }}>User Name</th>
                        <th style={{ fontFamily: 'LexendRegular400' }}>Email / Phone</th>     
                        <th style={{ fontFamily: 'LexendRegular400' }}>Actions</th>
                    </tr>
                    {allBlockedUsers.map((val, key) => {
                        return(
                            <tr className='adminRow' key={key}>
                                <td>{key+1}</td>
                                <td>{val.blockedUserName}</td>
                                <td>{val.blockedUserEmail}</td>
                               
                                <td>
                                    <button onClick={() => handleUnblockUserInitial(val.blockedUserEmail)} style={{marginTop: 0, color: '#FF0000', backgroundColor: '#FFF2F2', width: 100, paddingTop: 10, paddingBottom: 10, borderStyle: 'none', fontFamily: 'LexendMedium500', fontSize: 16 }}>Unblock</button> <br />
                                </td>
                            </tr>
                        )
                    })}
                </table>
                <div style={{ display:'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                    <button onClick={pagePrevious} style={{ cursor: 'pointer', width: 35, height: 30, borderWidth: 0, backgroundColor: '#0a2e28' }}>
                        <FiChevronLeft size={24} color='white' style={{ marginTop: 2, marginLeft: -2 }} />
                    </button>
                    <input style={{ fontFamily:'LexendMedium500', fontSize: 14, display: 'flex', justifyContent: 'center',alignItems: 'center' ,marginLeft: 20,width: 30, textAlign: 'center', height: 25, borderWidth: 1, borderColor: '#999999', borderStyle: 'solid' }}
                        value={page}
                        onChange={pageChangeInput}
                    />
                    <div style={{ marginLeft: 10, marginRight: 10,  fontSize: 14 }}>/</div>
                    <div style={{fontFamily: 'LexendMedium500', fontSize: 14 }}>{pagination.totalPages}</div>
                    <button onClick={pageNext} style={{cursor: 'pointer',marginLeft: 20, width: 35, height: 30, borderWidth: 0, backgroundColor: '#0a2e28' }}>
                        <FiChevronRight size={24} color='white' style={{ marginTop: 2 }} />
                    </button>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}