import React, { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoutes = (props) => {
  const userData = JSON.parse(localStorage.getItem("auth")) || null;
  if (!userData?.token) {
    return <Outlet />;
  } else if (userData && userData.token && userData?.role !== "Users") {
    return <Navigate to="/" />;
  }
};

export default memo(PublicRoutes);
