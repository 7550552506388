import "./Styles/EditProgramHomeClip.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../Images/AddAdmin/back.png";
import { useState, useEffect, useRef } from "react";
import api from "../Contexts/BaseUrl";
import { RiDeleteBin6Line } from "react-icons/ri";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import { AiOutlinePlus, AiOutlinePlusSquare } from "react-icons/ai";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { BiImage } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import tus from "tus-js-client";
import axios from "axios";
import Compressor from "compressorjs";
import {
  uploadImage,
  uploadFiles,
  isVimeoVideo,
} from "../Components/helpers/fileUpload";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
export default function EditProgramHomeClip() {
  let params = useParams();
  var dayId = params.dayId;
  var dayNumber = params.dayNumber;
  var programId = params.programId;

  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [aspect, setAspect] = useState();
  const [key, setKey] = useState("");
  const [fileupdated, setFileupdated] = useState();

  const hiddenInputImageUpload = useRef(null);
  const hiddenInputImageUpload1 = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [tempUploadedImageUrl1, setTempUploadedImageUrl1] = useState("");
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";
  const ffmpeg = new FFmpeg();

  const handleConvert = async (file) => {
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }
    await ffmpeg.writeFile("input.gif", await fetchFile(file));

    // Run the FFmpeg command to convert GIF to MP4
    await ffmpeg.exec([
      "-i",
      "input.gif",
      "-vcodec",
      "libx264",
      "-pix_fmt",
      "yuv420p",
      "-profile:v",
      "baseline",
      "-level",
      "3.0",
      "output.mp4",
    ]);

    // Read the result

    const data = await ffmpeg.readFile("output.mp4");

    // Create a Blob URL to download or display the video
    const mp4Blob = new Blob([data.buffer], {
      type: "video/mp4",
    });
    const mp4Url = URL.createObjectURL(mp4Blob);

    return mp4Blob;
  };
  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };

  const handleClickUploadGif = (e) => {
    hiddenInputImageUpload1.current.click();
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const handleChangeUploadedImage = async (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setFileupdated(newFile);
      setThumbnail(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setCrop({ aspect: 16 / 9 });
      const compressedImage = await compressImage(newFile, 800);
      const thumbnailBlob = await compressImage(newFile, 800);
      const fixedAspectRatioImage = new File([compressedImage], newFile.name, {
        type: newFile.type,
      });

      setTempUploadedImageUrl(URL.createObjectURL(compressedImage));
      setFile(fixedAspectRatioImage);
      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
  };

  const handleChangeUploadedgif = async (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setVideoFile(newFile);

      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      const compressGifImage = await compressImage(newFile, 800);

      setTempUploadedImageUrl1(URL.createObjectURL(newFile));
      // setVideoFile(compressGifImage);

      if (hiddenInputImageUpload1.current) {
        hiddenInputImageUpload1.current.value = "";
      }
    }
  };

  const handleCropComplete = (crop) => {
    console.log(crop);
    if (file && crop.width && crop.height) {
      getCroppedImg(file, crop, `cropped_${file.name}`);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert the canvas content to a blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        blob.name = fileName;
        resolve(blob);
      }, "image/jpeg"); // You can change the format here if needed
    });
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setEditRoundExerciseImageUrl(null);
    setFile(null);
    setThumbnail(null);
  };

  const handleCancelImageEdit = () => {
    setFile(null);
    setEditRoundExerciseImageUrl(null);
  };

  const handleCancelImage1 = () => {
    setTempUploadedImageUrl1("");
    setEditRoundExerciseImageUrl(null);
    setFile(null);
    setThumbnail(null);
  };

  // Upload Image to AWS Ends

  //Video Upload Starts
  const [dayVideoAvailable, setDayVideoAvailable] = useState(null);
  const [videoFile, setVideoFile] = useState(null); //Video file
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [videoUploadSuccess, setVideoUploadSuccess] = useState(false);
  const [failedUpload, setFailedUplaod] = useState(false);
  const hiddenInputVideoUpload = useRef(null);
  const [tempUploadedVideoUrl, setTempUploadedVideoUrl] = useState(null);
  const handleClickUploadVideo = (e) => {
    hiddenInputVideoUpload.current.click();
  };

  const handleChangeUploadedVideo = (e) => {
    if (e.target.files.length > 0) {
      setVideoFile(e.target.files[0]);
      const videoUrl = URL.createObjectURL(e.target.files[0]);
      setTempUploadedVideoUrl(videoUrl);
    }
    if (hiddenInputVideoUpload.current) {
      hiddenInputVideoUpload.current.value = "";
    }
  };

  const [deleteExistingVideo, setDeleteExistingVideo] = useState(false);
  const handleDeleteExistingVideo = async () => {
    await api
      .post(
        "/days/update-video-url",
        {
          dayId: dayId,
          dayVideoUrl: null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDeleteExistingVideo(false);
        fetchIndividualDay();
      })
      .catch((err) => {
        setDeleteExistingVideo(false);
      });
  };

  const handleRemoveVideo = () => {
    if (dayVideoAvailable) {
      setDeleteExistingVideo(true);
    } else {
      setVideoFile(null);
      setTempUploadedVideoUrl(null);
    }
  };

  const handleUploadVideo = async () => {
    setIsVideoLoading(true);

    const { Location } = await uploadFiles(videoFile);

    var urlVideo = `${VIDEO_URL + videoFile.name}`;

    await api
      .post(
        "/days/update-video-url",
        {
          dayId: dayId,
          dayVideoUrl: urlVideo,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsVideoLoading(false);
        fetchIndividualDay();
        setVideoFile(null);
        setVideoUploadSuccess(true);
      })
      .catch((err) => {
        setFailedUplaod(true);
        setIsVideoLoading(false);
      });
  };
  //Video Upload Ends
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddRound, setIsAddRound] = useState(false);
  const [rounds, setRounds] = useState([]);
  const [roundNumber, setRoundNumber] = useState("");
  //round Exercises starts
  const [isRoundExercises, setIsRoundExercises] = useState(false);
  const [roundIdExercises, setRoundIdExercises] = useState(0);
  const [roundNumberExercise, setRoundNumberExercise] = useState(0);
  const [individualRoundExercises, setIndividualRoundExercises] = useState([]);

  function handleRoundExercises(roundId) {
    setRoundIdExercises(roundId);
    setIsRoundExercises(true);

    api
      .get(`/rounds/get-individual-round?roundId=${roundId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        setIndividualRoundExercises(response.data.roundExercises);
        console.log("roundExercise::", response.data.roundExercises);

        setRoundNumberExercise(response.data.roundName);
      });
  }

  //round exercises ends

  const [roundId, setRoundId] = useState(0);
  const [isAddRoundExercise, setIsAddRoundExercise] = useState(false);
  const [roundExerciseTitle, setRoundExerciseTitle] = useState("");
  const [roundExerciseTimeLength, setRoundExerciseTimeLength] = useState("");
  const [isDeleteRound, setIsDeleteRound] = useState(false);
  const [deleteRoundId, setDeleteRoundId] = useState(0);
  const [roundExerciseId, setRoundExerciseId] = useState(0);
  const [isEditRoundExercise, setIsEditRoundExercise] = useState(false);
  const [editRoundExerciseTitle, setEditRoundExerciseTitle] = useState("");
  const [editRoundExerciseTimeLength, setEditRoundExerciseTimeLength] =
    useState("");
  const [editRoundExerciseImageUrl, setEditRoundExerciseImageUrl] =
    useState(null);
  const [isDeleteRoundExercise, setIsDeleteRoundExercise] = useState(false);
  const [deleteRoundExerciseId, setDeleteRoundExerciseId] = useState(0);
  var roundExercisesMap = new Map();
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [roundExercises, setRoundExercises] = useState([]);
  var user = JSON.parse(localStorage.getItem("auth"));

  useEffect(() => {
    if (user) {
      setToken(user.token);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchIndividualDay();
    }
  }, [token]);

  const handleChange = async (eventObject) => {
    // Get the selected file from the input element
    const file = eventObject.target.files[0];
    const fileName = file.name;
    const fileSize = file.size.toString();
    console.log(file, fileName, fileSize);
    const response = await axios({
      method: "post",
      url: `https://api.vimeo.com/me/videos`,
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer 3cabc12dd8e260b3915b82c9013225b6`,
        "Content-Type": "application/json",
      },
      data: {
        upload: {
          approach: "tus",
          size: fileSize,
        },
      },
    });
    // Create a new tus upload
    const upload = new tus.Upload(file, {
      endPoint: "https://api.vimeo.com/me/videos",
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {},
      onError: function (error) {
        console.log("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: function () {
        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });
    // Start the upload
    upload.start();
  };
  //getting individual rounds
  const fetchRoundExercises = (roundId, i, roundsLength) => {
    api
      .get(`/rounds/get-individual-round?roundId=${roundId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        roundExercisesMap.set(
          response.data.roundNumber,
          response.data.roundExercises
        );
        if (i === roundsLength - 1) {
          var arr = [];
          var arrRoundNumber = [];
          for (const value of roundExercisesMap.values()) {
            arr.push(value);
          }
          for (const key of roundExercisesMap.keys()) {
            arrRoundNumber.push(key);
          }
          setRoundExercises(arr);
          setRoundNumbers(arrRoundNumber);
        }
      });
  };
  const fetchIndividualDay = () => {
    api
      .get(`/Days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRounds(response.data.rounds);
        for (var i = 0; i < response.data.rounds.length; i++) {
          fetchRoundExercises(
            response.data.rounds[i].roundId,
            i,
            response.data.rounds.length
          );
        }
      });
  };

  const [roundIdFromResponse, setRoundIdFromResponse] = useState(null);
  const handleAddRound = async (e) => {
    e.preventDefault();
    await api
      .post(
        `/rounds/create-round?dayId=${dayId}`,
        {
          roundName: roundNumber,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response?.data.roundId);
        setRoundIdFromResponse(response?.data.roundId);
        fetchIndividualDay();
        handleAddRoundExerciseInitial(response?.data.roundId);
        setRoundNumber(null);
        setIsAddRound(false);
      });
  };

  const handleAddRoundCancel = () => {
    setRoundNumber(null);
    setIsAddRound(false);
  };

  function handleAddRoundExerciseInitial(roundId) {
    console.log(roundId);
    setRoundId(roundId ? roundId : roundIdFromResponse);
    setIsAddRoundExercise(true);
  }

  const handleAddRoundExerciseFinal = async (e) => {
    console.log(videoFile, file);
    e.preventDefault();
    // debugger;
    setIsLoading(true);
    if (file === null && videoFile === null) {
      await api
        .post(
          `/roundExercises/create-round-exercise?roundId=${roundIdFromResponse}`,
          {
            roundExerciseTitle: roundExerciseTitle,
            roundExerciseTimeLength: roundExerciseTimeLength,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRoundExerciseTitle("");
          setRoundExerciseTimeLength("");
          setFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedImageUrl1("");
          fetchIndividualDay();
          setIsAddRoundExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else if (file === null && videoFile !== null) {
      const urlVideo = await uploadFiles(await handleConvert(videoFile));

      await api
        .post(
          `/roundExercises/create-round-exercise?roundId=${roundId}`,
          {
            roundExerciseTitle: roundExerciseTitle,
            roundExerciseTimeLength: roundExerciseTimeLength,
            roundExerciseVideoUrl: urlVideo.Key,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRoundExerciseTitle("");
          setRoundExerciseTimeLength("");
          setFile(null);
          setTempUploadedImageUrl("");
          setVideoFile(null);
          setTempUploadedVideoUrl(null);
          fetchIndividualDay();
          setIsAddRoundExercise(false);
          setIsLoading(false);
          setTempUploadedImageUrl("");
          setEditRoundExerciseImageUrl(null);
          setFile(null);
          setTempUploadedImageUrl1("");
        })
        .catch((err) => {
          setIsLoading(false);
          setFile(null);
          setVideoFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedVideoUrl(null);
        });
    } else if (file !== null && videoFile === null) {
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);
      await api
        .post(
          `/roundExercises/create-round-exercise?roundId=${roundId}`,
          {
            roundExerciseTitle: roundExerciseTitle,
            roundExerciseTimeLength: roundExerciseTimeLength,
            roundExerciseImageUrl: imageResponse.Location,
            roundExerciseThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRoundExerciseTitle("");
          setRoundExerciseTimeLength("");
          setFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedImageUrl1("");
          fetchIndividualDay();
          setIsAddRoundExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedImageUrl1("");
        });
    } else if (file !== null && videoFile !== null) {
      const { Location: imageLocation } = await uploadImage(file);

      const urlVideo = await uploadFiles(await handleConvert(videoFile));
      const thumbnailResponse = await uploadImage(thumbnail);
      await api
        .post(
          `/roundExercises/create-round-exercise?roundId=${roundId}`,
          {
            roundExerciseTitle: roundExerciseTitle,
            roundExerciseTimeLength: roundExerciseTimeLength,
            roundExerciseImageUrl: imageLocation,
            roundExerciseVideoUrl: urlVideo.Key,
            roundExerciseThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRoundExerciseTitle("");
          setRoundExerciseTimeLength("");
          setFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedImageUrl1("");
          setVideoFile(null);
          setTempUploadedVideoUrl(null);
          fetchIndividualDay();
          setIsAddRoundExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setFile(null);
          setVideoFile(null);
          setTempUploadedImageUrl("");
          setTempUploadedImageUrl1("");
          setTempUploadedVideoUrl(null);
        });
    }
  };

  const handleAddRoundExerciseCancel = () => {
    setRoundExerciseTimeLength("");
    setRoundExerciseTitle("");
    setIsAddRoundExercise(false);
  };

  function handleEditRoundExerciseInitial(roundExerciseId) {
    setRoundExerciseId(roundExerciseId);
    setIsEditRoundExercise(true);
    fetchIndividualRoundExercise(roundExerciseId);
  }

  const handleEditRoundExerciseFinal = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          "/roundExercises/edit-round-exercise",
          {
            roundExerciseId: roundExerciseId,
            roundExerciseTitle: editRoundExerciseTitle,
            roundExerciseTimeLength: editRoundExerciseTimeLength,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setEditRoundExerciseTitle("");
          setEditRoundExerciseTimeLength("");
          fetchIndividualDay();
          setIsEditRoundExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const { Location } = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);

      await api
        .post(
          "/roundExercises/edit-round-exercise",
          {
            roundExerciseId: roundExerciseId,
            roundExerciseTitle: editRoundExerciseTitle,
            roundExerciseTimeLength: editRoundExerciseTimeLength,
            roundExerciseImageUrl: Location,
            roundExerciseThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setEditRoundExerciseTitle("");
          setEditRoundExerciseTimeLength("");
          fetchIndividualDay();
          setIsEditRoundExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleEditRoundExerciseCancel = () => {
    setEditRoundExerciseTitle("");
    setEditRoundExerciseTimeLength("");
    setIsEditRoundExercise(false);
  };

  function fetchIndividualRoundExercise(roundExerciseId) {
    api
      .get(
        `/roundExercises/get-individual-round-exercise?roundExerciseId=${roundExerciseId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setEditRoundExerciseTitle(response.data.roundExerciseTitle);
        setEditRoundExerciseTimeLength(response.data.roundExerciseTimeLength);
        setEditRoundExerciseImageUrl(response.data.roundExerciseImageUrl);
      });
  }

  function handleDeleteRoundInitial(roundId) {
    setIsDeleteRound(true);
    setDeleteRoundId(roundId);
  }

  const handleDeleteRoundFinal = async (e) => {
    e.preventDefault();
    setIsDeleteRound(true);
    setIsLoading(true);

    await api
      .post(`/rounds/delete-round?roundId=${deleteRoundId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        fetchIndividualDay();
        setIsDeleteRound(false);
      });
  };

  function handleDeleteRoundExerciseInitial(roundExerciseId) {
    setIsDeleteRoundExercise(true);
    setDeleteRoundExerciseId(roundExerciseId);
  }

  const handleDeleteRoundExerciseFinal = async (e) => {
    e.preventDefault();
    await api
      .post(
        `/roundExercises/delete-round-exercise?roundExerciseId=${deleteRoundExerciseId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        fetchIndividualDay();
        setIsDeleteRoundExercise(false);
      });
  };
  const playerRef = useRef(null);
  return (
    <div className="editProgramHomeClipPage">
      {isRoundExercises && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal4">
            <div
              onClick={() => {
                setIsRoundExercises(false);
                setIndividualRoundExercises([]);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                height: 40,
                borderRadius: 30,
                borderStyle: "none",
                position: "absolute",
                right: -20,
                marginTop: -45,
                backgroundColor: "black",
              }}
            >
              <ImCross size={20} style={{ color: "white" }} />
            </div>
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              Round {roundNumberExercise}
            </li>
            <div className="clip-modal-exercise-wrapper">
              {individualRoundExercises.map((item, keyVal) => {
                return (
                  <div
                    key={keyVal}
                    style={{
                      display: "flex",
                      marginBottom: 40,
                      fontFamily: "LexendMedium500",
                      fontSize: 16,
                    }}
                  >
                    <div
                      style={{
                        width: 40,
                        height: 120,
                        backgroundColor: "#FAFAFD",
                        borderStyle: "none",
                      }}
                    >
                      <div
                        style={{
                          borderStyle: "solid",
                          borderColor: "#4176FF",
                          borderWidth: 2,
                        }}
                      />
                      <li style={{ textAlign: "center", marginTop: 50 }}>
                        {keyVal + 1}
                      </li>
                    </div>
                    <div
                      style={{
                        marginLeft: 30,
                        width: 120,
                        height: 120,
                        backgroundColor: "#FAFAFD",
                        borderStyle: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 30,
                      }}
                    >
                      {item.roundExerciseImageUrl !== null ? (
                        <img
                          src={item.roundExerciseImageUrl}
                          alt="roundExercise"
                          style={{ width: 120, height: 120, resize: "cover" }}
                        />
                      ) : (
                        <BiImage size={50} style={{ color: "#A5D7E8" }} />
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 30,
                        width: 120,
                        height: 120,
                        backgroundColor: "#FAFAFD",
                        borderStyle: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.roundExerciseVideoUrl !== null ? (
                        // <VimeoPlayer
                        //   video={item.roundExerciseVideoUrl}
                        //   autoplay
                        //   className="video-wrapper"
                        // />
                        // <img
                        //alt=""
                        //width="120"
                        // height="120"
                        // src={VIDEO_URL + item.roundExerciseVideoUrl}
                        ///>
                        <video
                          style={{
                            width: 160,
                            height: 130,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source
                            src={
                              VIDEO_URL +
                              isVimeoVideo(item.roundExerciseVideoUrl)
                            }
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <li>GIF</li>
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 60,
                        width: 300,
                        height: 120,
                        borderStyle: "none",
                      }}
                    >
                      <div
                        style={{
                          height: 50,
                          borderStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FAFAFD",
                        }}
                      >
                        {item.roundExerciseTitle}
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                          height: 50,
                          borderStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FAFAFD",
                        }}
                      >
                        {item.roundExerciseTimeLength}
                      </div>
                    </div>
                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                      <div
                        onClick={() =>
                          handleDeleteRoundExerciseInitial(item.roundExerciseId)
                        }
                        style={{
                          cursor: "pointer",
                          width: 35,
                          height: 35,
                          borderRadius: 20,
                          borderStyle: "solid",
                          borderColor: "#FF0000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <RiDeleteBin6Line
                          size={20}
                          style={{ color: "#FF0000" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {isDeleteRound && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal3">
            <li style={{ textAlign: "center", marginTop: 50 }}>
              Are you sure you want to delete
            </li>
            <li style={{ textAlign: "center" }}>this round?</li>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={handleDeleteRoundFinal}
                style={{
                  marginRight: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#ffba26",
                  color: "#0a2e28",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                {isLoading ? (
                  <Spinner
                    size={10}
                    style={{
                      color: "white",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                onClick={() => setIsDeleteRound(false)}
                style={{
                  marginLeft: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#e63946",
                  color: "white",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      {isDeleteRoundExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal3">
            <li style={{ textAlign: "center", marginTop: 50 }}>
              Are you sure you want to delete
            </li>
            <li style={{ textAlign: "center" }}>this round exercise?</li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={handleDeleteRoundExerciseFinal}
                style={{
                  marginRight: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#ffba26",
                  color: "#0a2e28",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteRoundExercise(false)}
                style={{
                  marginLeft: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#e63946",
                  color: "white",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      {isAddRound && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal" onSubmit={handleAddRound}>
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 18,
                marginTop: 35,
                marginLeft: 20,
              }}
            >
              Add New Round
            </li>
            <div
              style={{
                display: "flex",
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 20,
                marginTop: 20,
                borderStyle: "none",
              }}
            >
              <li
                style={{
                  fontFamily: "LexendBold700",
                  fontSize: 14,
                  marginTop: 10,
                }}
              >
                Round Name
              </li>
              <input
                required
                type="text"
                placeholder="Enter Round Name"
                value={roundNumber}
                onChange={(e) => setRoundNumber(e.target.value)}
                style={{
                  width: 340,
                  height: 50,
                  borderStyle: "none",
                  paddingLeft: 20,
                  fontFamily: "LexendRegular400",
                }}
              />
            </div>

            <button
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#F38CAC",
                color: "#ffffff",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 10,
                width: 150,
                height: 45,
              }}
            >
              Add
            </button>
            <button
              onClick={handleAddRoundCancel}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 35,
                width: 150,
                height: 45,
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}
      {isAddRoundExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal2" onSubmit={handleAddRoundExerciseFinal}>
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 18,
                marginTop: 20,
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              Add Round Exercise
            </li>
            <div
              style={{
                display: "flex",
                marginBottom: 40,
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              {tempUploadedImageUrl.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    marginLeft: 30,
                    width: 120,
                    height: 120,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 100,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 30,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 14,
                      marginTop: 5,
                      textAlign: "center",
                    }}
                  >
                    Upload Image
                  </li>
                </div>
              ) : (
                <div style={{}}>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        marginLeft: 30,
                        width: 120,
                        height: 120,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 2,
                      paddingBottom: 2,
                      marginLeft: 40,
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              {tempUploadedImageUrl1.length === 0 ? (
                <div
                  onClick={handleClickUploadGif}
                  style={{
                    marginLeft: 30,
                    width: 120,
                    height: 120,
                    backgroundColor: "#ffffff",
                    borderStyle: "none",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="upload"
                    style={{
                      maxHeight: 100,
                      display: "block",
                      marginTop: 30,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 14,
                      marginTop: 5,
                      textAlign: "center",
                    }}
                  >
                    Upload GIF
                  </li>
                </div>
              ) : (
                <div>
                  {/* <video
                    style={{
                      width: 160,
                      height: 130,
                      borderStyle: "none",
                      display: "block",
                    }}
                    controls
                  >
                    <source src={tempUploadedVideoUrl} type="video/mp4" />
                  </video> */}
                  <img
                    src={tempUploadedImageUrl1}
                    alt="uploadedImage1"
                    style={{
                      marginLeft: 30,
                      width: 120,
                      height: 120,
                      resize: "cover",
                      cursor: "pointer",
                    }}
                  />
                  <button
                    onClick={handleCancelImage1}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 2,
                      paddingBottom: 2,
                      marginLeft: 40,
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    Remove GIF
                  </button>
                </div>
              )}
              <div
                style={{
                  marginLeft: 60,
                  width: 300,
                  height: 120,
                  borderStyle: "none",
                }}
              >
                <input
                  required
                  placeholder="Round Exercise Title"
                  value={roundExerciseTitle}
                  onChange={(e) => setRoundExerciseTitle(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    height: 50,
                    width: 270,
                    borderStyle: "none",
                    fontFamily: "LexendMedium500",
                  }}
                />
                <input
                  required
                  placeholder="Round Exercise Time Length"
                  value={roundExerciseTimeLength}
                  onChange={(e) => setRoundExerciseTimeLength(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    marginTop: 15,
                    height: 50,
                    width: 270,
                    borderStyle: "none",
                    fontFamily: "LexendMedium500",
                  }}
                />
                <li style={{ fontSize: 12, marginTop: 5 }}>*Format: MM:SS</li>
              </div>
            </div>
            {!isLoading ? (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                Add
              </button>
            ) : (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                <Spinner
                  size={18}
                  style={{
                    color: "white",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                />
              </button>
            )}
            <button
              onClick={handleAddRoundExerciseCancel}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 35,
                width: 150,
                height: 45,
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}
      {isEditRoundExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal2" onSubmit={handleEditRoundExerciseFinal}>
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 18,
                marginTop: 20,
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              Edit Round Exercise
            </li>
            <div
              style={{
                display: "flex",
                marginBottom: 40,
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              <div>
                {editRoundExerciseImageUrl === null ? (
                  <div
                    onClick={handleClickUploadImage}
                    style={{
                      marginLeft: 30,
                      width: 120,
                      height: 120,
                      backgroundColor: "#ffffff",
                      borderStyle: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={uploadImageIcon}
                      alt="upload"
                      style={{
                        maxHeight: 100,
                        display: "block",
                        paddingTop: 30,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <li
                      style={{
                        fontFamily: "LexendMedium500",
                        fontSize: 14,
                        marginTop: 5,
                        textAlign: "center",
                      }}
                    >
                      Upload Image
                    </li>
                  </div>
                ) : (
                  <>
                    <div onClick={handleClickUploadImage}>
                      <img
                        src={editRoundExerciseImageUrl}
                        alt="roundexercise"
                        style={{
                          marginLeft: 30,
                          width: 120,
                          height: 120,
                          resize: "cover",
                        }}
                      />
                    </div>
                    <button
                      onClick={handleCancelImageEdit}
                      style={{
                        marginLeft: 45,
                        marginTop: 10,
                        fontFamily: "LexindSemiBold600",
                        fontSize: 12,
                      }}
                    >
                      Remove Image
                    </button>
                  </>
                )}
              </div>
              <div
                style={{
                  marginLeft: 30,
                  width: 120,
                  height: 120,
                  backgroundColor: "#ffffff",
                  borderStyle: "none",
                }}
              >
                <img
                  src={uploadImageIcon}
                  alt="upload"
                  style={{
                    maxHeight: 100,
                    display: "block",
                    marginTop: 30,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <li
                  style={{
                    fontFamily: "LexendMedium500",
                    fontSize: 14,
                    marginTop: 5,
                    textAlign: "center",
                  }}
                >
                  Upload Video
                </li>
              </div>
              <div
                style={{
                  marginLeft: 60,
                  width: 300,
                  height: 150,
                  borderStyle: "none",
                }}
              >
                <input
                  required
                  placeholder="Round Exercise Title"
                  value={editRoundExerciseTitle}
                  onChange={(e) => setEditRoundExerciseTitle(e.target.value)}
                  style={{
                    paddingLeft: 20,
                    height: 50,
                    width: 270,
                    borderStyle: "none",
                    fontFamily: "LexendMedium500",
                  }}
                />
                <input
                  required
                  placeholder="Round Exercise Time Length"
                  value={editRoundExerciseTimeLength}
                  onChange={(e) =>
                    setEditRoundExerciseTimeLength(e.target.value)
                  }
                  style={{
                    paddingLeft: 20,
                    marginTop: 15,
                    height: 50,
                    width: 270,
                    borderStyle: "none",
                    fontFamily: "LexendMedium500",
                  }}
                />
                <li style={{ fontSize: 12, marginTop: 5 }}>Format: MM:SS</li>
              </div>
            </div>
            {!isLoading ? (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                Save
              </button>
            ) : (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                <Spinner
                  size={18}
                  style={{
                    color: "white",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                />
              </button>
            )}
            <button
              onClick={handleEditRoundExerciseCancel}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 35,
                width: 150,
                height: 45,
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}
      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />

        {/* <ReactCrop
          src={tempUploadedImageUrl}
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(newCrop) => handleCropComplete(newCrop)}
          aspect={aspect}
        > */}
        <input
          key={key}
          type="file"
          accept="image/*"
          ref={hiddenInputImageUpload}
          onChange={handleChangeUploadedImage}
          style={{ display: "none" }}
        />
        {/* </ReactCrop> */}

        <input
          key={key}
          type="file"
          accept="image/gif"
          ref={hiddenInputImageUpload1}
          onChange={handleChangeUploadedgif}
          // onChange={handleChange}
          style={{ display: "none" }}
        />
        <div className="mainBody">
          <div
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
              {" "}
              <span style={{ color: "#88869A" }}>Day {dayNumber}</span> - Home
              Clip
            </li>
            {/* <button onClick={() => navigate(`/preview-program/${programId}`)} style={{ width: 140, height: 50, display: 'block',   backgroundColor: '#F38CAC', fontFamily: 'LexendBold700', fontSize: 18, color: '#ffffff', borderStyle: 'none' }}>Preview</button> */}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 40,
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: 720, borderStyle: "none" }}>
              <li
                style={{
                  fontFamily: "LexendSemiBold600",
                  fontSize: 24,
                  marginBottom: 25,
                }}
              >
                Rounds
              </li>
              <div>
                {rounds.map((val, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        marginBottom: 40,
                        fontFamily: "LexendSemiBold600",
                        fontSize: 18,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#ffffff",
                          borderStyle: "none",
                          paddingTop: 0,
                          marginBottom: 20,
                        }}
                      >
                        <li>{val.roundName}</li>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            onClick={() => handleRoundExercises(val.roundId)}
                            style={{
                              cursor: "pointer",
                              marginTop: 5,
                              marginRight: 5,
                            }}
                          >
                            <HiOutlineEye
                              size={30}
                              style={{ color: "#4176FF" }}
                            />
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                              marginTop: 5,
                              marginRight: 10,
                            }}
                            onClick={() =>
                              handleAddRoundExerciseInitial(rounds[key].roundId)
                            }
                          >
                            <AiOutlinePlusSquare size={35} />
                          </div>
                          <div
                            onClick={() =>
                              handleDeleteRoundInitial(rounds[key].roundId)
                            }
                            style={{
                              cursor: "pointer",
                              width: 35,
                              height: 35,
                              borderRadius: 20,
                              borderStyle: "solid",
                              borderColor: "#FF0000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <RiDeleteBin6Line
                              size={20}
                              style={{ color: "#FF0000" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button
                onClick={() => setIsAddRound(true)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                  height: 50,
                  width: "100%",
                  borderStyle: "none",
                }}
              >
                <AiOutlinePlus size={18} />
                <li
                  style={{
                    fontFamily: "LexendMedium500",
                    fontSize: 16,
                    marginLeft: 10,
                  }}
                >
                  Add New Round
                </li>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
