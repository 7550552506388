import "./Styles/AddProgramWeeks.css";
import { useEffect, useState } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import { useParams, useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { AiOutlinePlus } from "react-icons/ai";

export default function AddProgramWeeks() {
  let params = useParams();
  var programId = params.programId;
  const [token, setToken] = useState(null);

  const [isAddWeek, setIsAddWeek] = useState(false);
  const [weekId, setWeekId] = useState(0);
  const [weekNumber, setWeekNumber] = useState(0);
  const [weekDays, setWeekDays] = useState([]);

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    api
      .get(`/workoutPrograms/get-individual-program?id=${programId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setWeekId(response.data.weeks[0].weekId);
        fetchIndividualWeek(response.data.weeks[0].weekId, user.token);
      });
  }, []);

  const fetchIndividualProgram = () => {
    api
      .get(`/workoutPrograms/get-individual-program?id=${programId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setWeekId(response.data.weeks[0].weekId);
      });
  };

  function fetchIndividualWeek(weekId, tokenVar) {
    api
      .get(`/weeks/get-individual-week?weekId=${weekId}`, {
        headers: { Authorization: `Bearer ${tokenVar}` },
      })
      .then((response) => {
        setWeekNumber(response.data.weekNumber);
        setWeekDays(response.data.days);
      });
  }

  return (
    <div className="addProgramWeeksPage">
      <LeftNavigationBar allPrograms={1} />
      <div className="mainBody">
        <div style={{ marginTop: 20 }}>
          <li
            style={{
              fontFamily: "LexendSemiBold600",
              fontSize: 24,
              marginTop: 10,
            }}
          >
            Week {weekNumber}
          </li>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {weekDays.map((val, key) => {
              return (
                <div
                  style={{
                    marginTop: 20,
                    background: "#ffffff",
                    width: 120,
                    height: 100,
                    borderStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <AiOutlinePlus
                      size={24}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <li style={{ fontFamily: "LexendMedium500", fontSize: 18 }}>
                      Day {val.dayNumber}
                    </li>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
              height: 50,
              width: "100%",
              borderStyle: "none",
            }}
          >
            <AiOutlinePlus size={18} />
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                marginLeft: 10,
              }}
            >
              Add Another Week
            </li>
          </button>
        </div>
      </div>
    </div>
  );
}
