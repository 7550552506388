import "./Styles/AddAdmin.css";
import { useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import eyeIcon from "../Images/AddAdmin/eye.png";
import eyeOnIcon from "../Images/AddAdmin/eyeOn.png";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

export default function AddAdmin() {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);

  const [eyeOn, setEyeOn] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("Admin");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
      setRole(user.role);
    }
  }, []);

  function showPassword() {
    var x = document.getElementById("myPassword");
    setEyeOn(!eyeOn);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const handleRole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectedRole === "Admin") {
      await api
        .post(
          "/accounts/register-admin",
          {
            name: name,
            email: email,
            password: password,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          navigate(-1);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      await api
        .post(
          "/accounts/register-superadmin",
          {
            name: name,
            email: email,
            password: password,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsLoading(false);
          navigate(-1);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="addAdminPage">
      <LeftNavigationBar allAdmins={1} />
      <div className="mainBody">
        {role === "Superadmin" && (
          <>
            <div style={{ height: 50 }} />

            <div
              onClick={() => navigate("/all-admins")}
              style={{
                cursor: "pointer",
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
                width: 40,
                height: 40,
                borderRadius: 25,
                borderStyle: "solid",
                borderColor: "#F38CAC",
                borderWidth: 1,
              }}
            >
              <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
            </div>
            <form onSubmit={handleRole}>
              <li
                style={{
                  marginBottom: 20,
                  fontSize: 24,
                  fontFamily: "LexendBold700",
                }}
              >
                Add New Role
              </li>
              <input
                type="text"
                required
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{
                  marginBottom: 20,
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: 300,
                  fontSize: 14,
                  fontFamily: "LexendRegular400",
                }}
              />
              <br />
              <input
                type="email"
                required
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  marginBottom: 20,
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: 300,
                  fontSize: 14,
                  fontFamily: "LexendRegular400",
                }}
              />
              <br />
              <input
                type="password"
                id="myPassword"
                required
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{
                  marginBottom: 20,
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: 300,
                  fontSize: 14,
                  fontFamily: "LexendRegular400",
                }}
              />
              {!eyeOn ? (
                <img
                  onClick={showPassword}
                  src={eyeIcon}
                  alt="eyeIcon"
                  style={{ maxHeight: 20, marginLeft: -30 }}
                />
              ) : (
                <img
                  onClick={showPassword}
                  src={eyeOnIcon}
                  alt="eyeIcon"
                  style={{ maxHeight: 20, marginLeft: -30 }}
                />
              )}
              <br />
              <select
                id="roles"
                name="roles"
                onChange={(e) => setSelectedRole(e.target.value)}
                style={{
                  marginBottom: 30,
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: 320,
                  borderStyle: "none",
                  fontFamily: "LexendRegular400",
                }}
              >
                <option value="Admin">Admin</option>
                <option value="Superadmin">Superadmin</option>
              </select>
              <br />
              {!isLoading ? (
                <button
                  style={{
                    width: 320,
                    height: 40,
                    fontFamily: "LexendMedium500",
                    background: "#4176FF",
                    color: "#ffffff",
                    fontSize: 18,
                    borderStyle: "none",
                  }}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  style={{
                    width: 320,
                    height: 40,
                    fontFamily: "LexendMedium500",
                    background: "#4176FF",
                    color: "#ffffff",
                    fontSize: 14,
                    borderStyle: "none",
                  }}
                >
                  <Spinner
                    size={15}
                    color="white"
                    style={{ diplay: "block", margin: "auto" }}
                  />
                </button>
              )}
              {isError && (
                <li
                  style={{
                    marginTop: 20,
                    marginLeft: 80,
                    fontFamily: "LexendMedium500",
                    fontSize: 18,
                    color: "red",
                  }}
                >
                  Failed! Try again later
                </li>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
}
