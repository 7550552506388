import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../Contexts/BaseUrl';

export default function VerfiyEmail() {
    const [searchParams] = useSearchParams();
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const emailSearch = searchParams.get('email');
    const handleManualConfirm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await api.post(`/accounts/manually-email-confirm?email=${emailSearch}`, {
            email: emailSearch
        }).then(response => {
            setEmailConfirmed(true);
            setIsLoading(false);
        })
    }
    return (
        <div style={{ backgroundColor: '#FAFAFD' }}>
            <>
                {emailConfirmed ?
                    <div style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold' }}> Email confirmed. Please Log In</div>
                    :
                    <>
                        {isLoading ?
                            <button style={{ width: 120, height: 30, background: 'blue', color: 'white', borderStyle: 'none', display: 'block', margin: 'auto', fontWeight: 'bold', borderRadius: 4 }}>Loading...</button>
                            :
                            <button onClick={handleManualConfirm} style={{ width: 120, height: 30, background: 'blue', color: 'white', borderStyle: 'none', display: 'block', margin: 'auto', fontWeight: 'bold', borderRadius: 4 }}>Verify Email</button>
                        }
                    </>
                }
            </>
        </div>
    )
}