export default function PrivacyPolicy(){
    return(
        <div style={{ width: '100%', background: 'white', fontFamily: 'LexendRegular400' }}>
            <div style={{ width: '75%', display: 'block', margin: 'auto', paddingTop: 20 }}>
                <h1>Terms & Conditions</h1>
                <h3>Effective Date: July 5th, 2023</h3>
                <p>
                Welcome to the Muscle Sisters Fitness App ("the App"). The App provides a range of fitness resources, workouts, and information tailored specifically for women. Before using the App, please carefully read and understand the following Terms and Conditions ("Terms") as they govern your use of the App:
                </p>

                {/* <h3>Information We Collect</h3> */}
                <p>
                1. Acceptance of Terms: By downloading, accessing, or using the Muscle Sisters Fitness App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, you must refrain from using the App.
                </p>
                <p>
                2. Age Restriction: The App is intended for individuals who are at least 18 years old. If you are under 18 years old, you may only use the App under the supervision of a parent or legal guardian who agrees to be bound by these Terms.
                </p>
                <p>
                3. User Account: To access certain features of the App, you may need to create a user account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information up to date. You are solely responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
                </p>
                <p>
                4. App Content: The Muscle Sisters Fitness App provides fitness resources, workouts, videos, articles, and other content ("Content"). This Content is for informational purposes only and should not be considered as medical or professional advice. Always consult with a qualified healthcare professional before starting any fitness or exercise program.
                </p>
                {/* <h3>How We Use Your Information</h3> */}
                <p>
                5. Intellectual Property: The Muscle Sisters Fitness App and its Content are protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, create derivative works, or exploit any part of the App or its Content without prior written permission from the Apps owner.
                </p>
                <p>
                6. User Conduct: You agree to use the Muscle Sisters Fitness App in a lawful and responsible manner. You will not use the App to upload, post, or transmit any content that is illegal, harmful, defamatory, obscene, or infringes upon the rights of others. You will not engage in any activity that disrupts or interferes with the proper functioning of the App.
                </p>
                <p>
                7. Privacy: The Muscle Sisters Fitness App collects and uses personal information in accordance with its Privacy Policy. By using the App, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
                </p>
                <p>
                8. Termination: The Muscle Sisters Fitness App reserves the right to suspend or terminate your access to the App at any time, without prior notice, for any reason or no reason at all. You may also terminate your use of the App at any time.
                </p>
                {/* <h3>Information Sharing and Disclosure</h3> */}
                <p>
                9. Disclaimer of Warranty: The Muscle Sisters Fitness App is provided on an "as is" and "as available" basis, without warranties of any kind, whether express or implied. The App does not guarantee the accuracy, reliability, or availability of its Content or the App itself.
                </p>
                <p>
                10. Limitation of Liability: In no event shall the Muscle Sisters Fitness App or its owners be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App or its Content.
                </p>
                <p>
                11. Modification of Terms: The Muscle Sisters Fitness App reserves the right to modify or update these Terms at any time. The updated Terms will be effective upon posting. Your continued use of the App after any modifications constitutes acceptance of the updated Terms.
                </p>    
                {/* <h3>Data Security</h3> */}
                <p>
                12. Governing Law: These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Muscle Sisters Fitness App operates.
                </p>
                <p>
                13. Subscription policy: The customer will be granted a complimentary 3-day trial period to explore the app. Following this trial period, they will be automatically billed for either the monthly subscription ($29.99) or the yearly subscription ($179.99), depending on their selection. Should the customer opt to cancel during the 3-day trial period, they will not incur charges for either the monthly or yearly subscription.
                </p>
                {/* <h3>Data Retention</h3> */}
                <p>
                If you have any questions or concerns about these Terms, please contact us at [contact information]. By using the Muscle Sisters Fitness App, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions.
                </p>
            </div>

        </div>
    )
}