import "./Styles/UserProfile.css";
import { useState, useEffect, useRef } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import api from "../Contexts/BaseUrl";
import { BiUser } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate } from "react-router-dom";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function UserProfile() {
  const [file, setFile] = useState(null);

  const handleRemoveImage = () => {
    setProfileImageUrl(null);
    setFile(null);
    setThumbnail(null);
  };

  const hiddenInputImageUpload = useRef(null);

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };

  // const handleChangeUploadedImage = (e) => {
  //     if (e.target.files.length > 0) {
  //         setFile(e.target.files[0]);
  //         const imageUrl = URL.createObjectURL(e.target.files[0]);
  //         setProfileImageUrl(imageUrl);
  //     }

  // }

  const [key, setKey] = useState("");
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setThumbnail(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);

      setProfileImageUrl(URL.createObjectURL(newFile));

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
  };

  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  const [joiningDate, setJoiningDate] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [editName, setEditName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [errorMsg, setErrMsg] = useState('Failed changing password');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsLoadingPassword(true);
    await api
      .post(
        "/accounts/change-password",
        {
          email: email,
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsChangePassword(false);
        setChangePasswordSuccess(true);
        setCurrentPassword("");
        setNewPassword("");
        setIsError(false);
        setIsLoadingPassword(false);
      })
      .catch((err) => {
        setIsError(true);
        setCurrentPassword("");
        setNewPassword("");
        setIsLoadingPassword(false);
      });
  };

  function handleCancelChangePassword() {
    setCurrentPassword("");
    setNewPassword("");
    setIsError(false);
    setIsChangePassword(false);
  }

  function handleForgotPassword() {
    api
      .post("/accounts/forgot-password", {
        email: email,
      })
      .then((response) => {
        setIsForgotPassword(true);
      });
  }

  useEffect(() => {
    const userObj = JSON.parse(localStorage.getItem("auth"));
    if (userObj) {
      setEmail(userObj.email);
      setToken(userObj.token);
    }

    api
      .get(`/accounts/get-account-holder?email=${userObj.email}`, {
        headers: { Authorization: `Bearer ${userObj.token}` },
      })
      .then((response) => {
        setName(response.data.name);
        setEditName(response.data.name);
        setJoiningDate(response.data.joiningDate);
        setProfileImageUrl(response.data.profileImageUrl);
      });
  }, []);

  const fetchAccountHolder = () => {
    api
      .get(`/accounts/get-account-holder?email=${email}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setName(response.data.name);
        setEditName(response.data.name);
        setJoiningDate(response.data.joiningDate);
        setProfileImageUrl(response.data.profileImageUrl);
      });
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          "/accounts/edit-user-details",
          {
            email: email,
            name: editName,
            profileImageUrl: profileImageUrl,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const userObj = JSON.parse(localStorage.getItem("auth"));
          if (userObj) {
            userObj.name = editName;
            localStorage.setItem("auth", JSON.stringify(userObj));
          }
          fetchAccountHolder();
          setEditProfile(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);
      await api
        .post(
          "/accounts/edit-user-details",
          {
            email: email,
            name: editName,
            profileImageUrl: imageResponse.Location,
            ProfileImageThumbnailUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const userObj = JSON.parse(localStorage.getItem("auth"));
          if (userObj) {
            userObj.name = editName;
            localStorage.setItem("auth", JSON.stringify(userObj));
          }
          fetchAccountHolder();
          setEditProfile(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleCancel = () => {
    setEditName(name);
    setEditProfile(false);
  };

  return (
    <>
      {isChangePassword && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal" onSubmit={handleChangePassword}>
            <li style={{ fontSize: 22 }}>Change Password</li>
            {isError && (
              <li style={{ textAlign: "center", color: "red", fontSize: 16 }}>
                Failed changing Password!
              </li>
            )}
            <li style={{ fontSize: 16, marginTop: 15, marginBottom: 10 }}>
              Current Password*
            </li>
            <input
              required
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Type Current Password"
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                width: 250,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            />
            <li style={{ fontSize: 16, marginTop: 15, marginBottom: 10 }}>
              New Password*
            </li>
            <input
              required
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Type New Password"
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 16,
                width: 250,
                paddingLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            />{" "}
            <br />
            {!isLoadingPassword ? (
              <button
                style={{
                  backgroundColor: "#F38CAC",
                  color: "white",
                  marginTop: 25,
                  height: 40,
                  width: 120,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderStyle: "none",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Save
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "#F38CAC",
                  color: "white",
                  marginTop: 25,
                  height: 40,
                  width: 120,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderStyle: "none",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Spinner
                  size={14}
                  style={{
                    color: "white",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </button>
            )}
            <button
              onClick={handleCancelChangePassword}
              style={{
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                marginTop: 15,
                height: 40,
                width: 120,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderStyle: "none",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}
      {changePasswordSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center" }}>Your password has been</li>
            <li style={{ textAlign: "center" }}>changed successfully!</li>
            <button
              onClick={() => setChangePasswordSuccess(false)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: 80,
                marginTop: 20,
                borderStyle: "none",
                height: 35,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                backgroundColor: "#F38CAC",
                color: "white",
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}

      {isForgotPassword && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center" }}>Password reset link has</li>
            <li style={{ textAlign: "center" }}>been sent to your email</li>
            <button
              onClick={() => setIsForgotPassword(false)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: 80,
                marginTop: 20,
                borderStyle: "none",
                height: 35,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                backgroundColor: "#F38CAC",
                color: "white",
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      <div className="userProfilePage">
        <LeftNavigationBar />
        <div>
          <div className="mainBody">
            <input
              key={key}
              type="file"
              accept="image/*"
              ref={hiddenInputImageUpload}
              onChange={handleChangeUploadedImage}
              style={{ display: "none" }}
            />
            <div
              style={{
                paddingTop: 50,
                paddingBottom: 50,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              {!editProfile ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
                      Profile
                    </li>
                    <button
                      onClick={() => setEditProfile(true)}
                      style={{
                        height: 40,
                        width: 100,
                        paddingTop: 5,
                        paddingBottom: 5,
                        fontSize: 18,
                        fontFamily: "LexendSemiBold600",
                        background: "#F38CAC",
                        color: "white",
                        borderStyle: "none",
                      }}
                    >
                      Edit
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      width: 100,
                      height: 100,
                      borderRadius: 50,
                      borderStyle: "none",
                      background: "#F5F5F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {profileImageUrl === null ? (
                      <BiUser size={50} style={{ color: "#F38CAC" }} />
                    ) : (
                      <img
                        src={profileImageUrl}
                        alt="profile"
                        style={{ width: 100, height: 100, borderRadius: 50 }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: 40,
                      fontFamily: "LexendMedium500",
                      fontSize: 18,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <li style={{ width: 100 }}>Name</li>
                      <li style={{ fontFamily: "LexendRegular400" }}>{name}</li>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <li style={{ width: 100 }}>Email</li>
                      <li style={{ fontFamily: "LexendRegular400" }}>
                        {email}
                      </li>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <li style={{ width: 100 }}>Joined</li>
                      <li style={{ fontFamily: "LexendRegular400" }}>
                        {joiningDate}
                      </li>
                    </div>
                  </div>
                </>
              ) : (
                <form onSubmit={handleUpdateProfile}>
                  <div
                    onClick={() => navigate(-1)}
                    style={{
                      cursor: "pointer",
                      marginBottom: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ffffff",
                      width: 40,
                      height: 40,
                      borderRadius: 25,
                      borderStyle: "solid",
                      borderColor: "#F38CAC",
                      borderWidth: 1,
                    }}
                  >
                    <img
                      src={backIcon}
                      alt="backicon"
                      style={{ maxHeight: 12 }}
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
                      Edit Profile
                    </li>

                    <div>
                      {!isLoading ? (
                        <button
                          style={{
                            height: 40,
                            width: 100,
                            paddingTop: 5,
                            paddingBottom: 5,
                            fontSize: 18,
                            fontFamily: "LexendSemiBold600",
                            background: "#F38CAC",
                            color: "white",
                            borderStyle: "none",
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          style={{
                            height: 40,
                            width: 100,
                            paddingTop: 5,
                            paddingBottom: 5,
                            fontSize: 18,
                            fontFamily: "LexendSemiBold600",
                            background: "#F38CAC",
                            color: "white",
                            borderStyle: "none",
                          }}
                        >
                          <Spinner
                            size={15}
                            style={{
                              color: "white",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </button>
                      )}
                      <button
                        onClick={handleCancel}
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          height: 40,
                          width: 100,
                          marginTop: 10,
                          fontFamily: "LexendSemiBold600",
                          fontSize: 18,
                          borderStyle: "none",
                          color: "#FF0000",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div>
                    {profileImageUrl === null ? (
                      <div>
                        <div
                          onClick={handleClickUploadImage}
                          style={{
                            cursor: "pointer",
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            backgroundColor: "#F5F5F5",
                            borderStyle: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <AiOutlinePlus
                            size={50}
                            style={{ color: "#F38CAC" }}
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              fontFamily: "LexendBold700",
                              fontSize: 28,
                            }}
                          >
                            Upload Image
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <img
                            src={profileImageUrl}
                            alt="profile"
                            style={{
                              width: 100,
                              height: 100,
                              borderRadius: 50,
                            }}
                          />
                        </div>

                        <button
                          onClick={handleRemoveImage}
                          style={{
                            fontFamily: "LexendRegular400",
                            fontSize: 12,
                            marginTop: 10,
                            borderStyle: "none",
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          Remove Image
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: 40,
                      fontFamily: "LexendMedium500",
                      fontSize: 18,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <li style={{ width: 100 }}>Name</li>
                      <input
                        type="text"
                        required
                        placeholder="Type your name"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        style={{
                          fontFamily: "LexendRegular400",
                          width: 200,
                          height: 35,
                          paddingLeft: 10,
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <li style={{ width: 100 }}>Email</li>
                      <li style={{ fontFamily: "LexendRegular400" }}>
                        {email}
                      </li>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <li style={{ width: 100 }}>Joined</li>
                      <li style={{ fontFamily: "LexendRegular400" }}>
                        {joiningDate}
                      </li>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div
            style={{
              marginLeft: 400,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => setIsChangePassword(true)}
              style={{ backgroundColor: "#F38CAC", color: "white" }}
              id="buttonUserProfile"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
