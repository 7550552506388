import React, { useEffect, useRef, useState } from "react";
import "./Styles/chat.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import api from "../Contexts/BaseUrl";
const Chatmessages = (props) => {
  const userData = JSON.parse(localStorage.getItem("auth")) || null;
  const [messages, setMessages] = useState([]);
  const [token, setToken] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const containerRef = useRef(null);

  const scrollToBottom = () => {
    if (containerRef.current && messages.length > 0) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    setToken(userData?.token);
    getMessagesData();
    createConnection();
    return () => {};
  }, []);
  
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    return () => {
      closeConnection();
    };
  }, []);

  const [connection, setConnection] = useState();
  const createConnection = async () => {
    try {
      const connectionData = new HubConnectionBuilder()
        .withUrl("/chat")
        .configureLogging(LogLevel.Information)
        .build();
      setConnection(connectionData);
    } catch (error) {
      console.error("Error creating SignalR connection:", error);
    }
  };

  const getMessagesData = async () => {
    const msgData = await api
      .get("/Chats/get-message", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        console.log(response?.data);
        setMessages(response?.data);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("ReceiveMessage", (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
          });
        })
        .catch((err) =>
          console.log("Error while establishing a connection :( ")
        );
    }
  }, [connection]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendMessage = async (message) => {
    try {
      let newData = {
        messageUserId: userData?.id,
        messageUserName: userData?.name,
        messageText: newMessage,
        userId: userData?.id,
        userName: userData?.name,
        messageText: newMessage,
      };
      // await connection.send("SendMessage", newData);
      await api
        .post("/Chats/SendMessage", newData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setIsLoading(false);
          setNewMessage("");
          setMessages([...messages, newData]);
          scrollToBottom();
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const closeConnection = async () => {
    if (connection) {
      try {
        await connection.stop();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    showMessages();
  }, [messages]);

  const convertTimeStamp = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp

    const hours = date.getHours(); // Get the hours
    const minutes = date.getMinutes(); // Get the minutes

    // Pad single-digit hours and minutes with a leading zero if necessary
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedTime = `${formattedHours}.${formattedMinutes}`; // Combine hours and minutes
    return formattedTime;
  };

  const showMessages = () => {
    return (
      <main className="msger-chat" ref={containerRef}>
        {messages && messages?.length > 0 ? (
          messages.map((item, index) => {
            return (
              <div
                className={`${
                  item?.userName == userData?.name
                    ? "msg right-msg"
                    : "msg left-msg"
                }`}
                key={`message_${index}`}
              >
                <div className="msg-bubble">
                  <div className="msg-info">
                    <div className="msg-info-name">{item?.userName}</div>
                    <div className="msg-info-time">
                      {item?.timestamp ? item?.timestamp : ""}
                    </div>
                  </div>

                  <div className="msg-text">{item?.messageText}</div>
                </div>
              </div>
            );
          })
        ) : (
          <>No Message to show</>
        )}
      </main>
    );
  };
  return (
    userData?.token && (
      <div className="chatPage">
        <div style={{ display: "flex" }}>
          <LeftNavigationBar allMessages={1} />
        </div>

        <div className="messages-page mainBody">
          <section className="msger" id="msger">
            <header className="msger-header">
              <div className="msger-header-options">
                <span>Chatbox</span>
              </div>
            </header>
            {showMessages()}
            <form className="msger-inputarea">
              <input
                type="text"
                className="msger-input"
                placeholder="Enter your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <button
                type="button"
                onClick={newMessage?.length > 0 ? () => sendMessage() : null}
                className="msger-send-btn"
              >
                Send
              </button>
            </form>
          </section>
        </div>
      </div>
      // </SignalRContext.Provider>
    )
  );
};

export default Chatmessages;
