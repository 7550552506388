import { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import AllPrograms from "./Pages/AllPrograms";
import UsersList from "./Pages/UsersList";
import Equipments from "./Pages/Equipments";
import AllAdmins from "./Pages/AllAdmins";
import AddAdmin from "./Pages/AddAdmin";
import UserDetails from "./Pages/UserDetails";
import AdminDetails from "./Pages/AdminDetails";
import AddNewProgram from "./Pages/AddNewProgram";
import EditProgramDetails from "./Pages/EditProgramDetails";
import AddProgramWeeks from "./Pages/AddProgramWeeks";
import EditProgramDayDetails from "./Pages/EditProgramDayDetails";
import EditProgramFollowAlong from "./Pages/EditProgramFollowAlong";
import EditProgramHomeClip from "./Pages/EditProgramHomeClip";
import PreviewProgram from "./Pages/PreviewProgram";
import UserProfile from "./Pages/UserProfile";
import BlockedUser from "./Pages/BlockedUser";
import Contact from "./Pages/Contact";
import Packages from "./Pages/Packages";
import Categories from "./Pages/Categories";
import VerifyEmail from "./Pages/VerifyEmail";
import ResetPassword from "./Pages/ResetPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import PublicGuard from "./Guard/PublicGuard";
import PrivateGuard from "./Guard/PrivateGuard";
import Chatmessages from "./Pages/Chatmessages";
import ForgetPassword from "./Pages/ForgetPassword";
import Recipe from "./Pages/Recipe";
import RecipeCategories from "./Pages/Recipe-Categories";
import AddNewRecipe from "./Pages/AddNewRecipe";
import PreviewRecipe from "./Pages/PreviewRecipe";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tips from "./Pages/tips";
import TipsCategories from "./Pages/TipsCategories";
import AddNewTip from "./Pages/AddNewTips";
import { Worker } from "@react-pdf-viewer/core";
import PreviewTips from "./Pages/PreviewTips";

function App() {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
      setRole(user.role);
    }
  }, []);

  return (
    <div className="App" style={{ backgroundColor: "blue" }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <ToastContainer />
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              // element={
              //   token !== null && role !== null && role !== "Users" ? (
              //     <Outlet />
              //   ) : (
              //     <Login />
              //   )
              // }
              element={<PrivateGuard />}
            >
              <Route path="" element={<Navigate replace to="home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/all-programs" element={<AllPrograms />} />
              <Route path="/add-new-program" element={<AddNewProgram />} />
              <Route path="/add-new-recipe" element={<AddNewRecipe />} />
              <Route path="/edit-recipe/:recipeId" element={<AddNewRecipe />} />

              <Route path="/add-new-tip" element={<AddNewTip />} />
              <Route
                path="/add-new-program/:programId/add-weeks"
                element={<AddProgramWeeks />}
              />
              <Route
                path="/preview-program/:programId"
                element={<PreviewProgram />}
              />
              <Route
                path="/preview-recipe/:recipeId"
                element={<PreviewRecipe />}
              />
              <Route path="/preview-tip/:tipId" element={<PreviewTips />} />
              <Route
                path="/edit-program/:programId"
                element={<EditProgramDetails />}
              />
              <Route
                path="/edit-program/:programId/day-details/:weekNumber/:dayId"
                element={<EditProgramDayDetails />}
              />
              <Route
                path="/edit-program/follow-along/:programId/:dayId/:dayNumber"
                element={<EditProgramFollowAlong />}
              />
              <Route
                path="/edit-program/follow-along/:programId/:dayId/:dayNumber/home-clip"
                element={<EditProgramHomeClip />}
              />
              <Route path="/users-list" element={<UsersList />} />
              <Route path="/chat" element={<Chatmessages />} />
              <Route path="/blocked-user" element={<BlockedUser />} />
              <Route path="/user-details/:userId" element={<UserDetails />} />
              <Route path="/equipments" element={<Equipments />} />
              <Route path="/recipe" element={<Recipe />} />
              <Route path="/tips" element={<Tips />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/recipe-categories" element={<RecipeCategories />} />
              <Route path="/tips-categories" element={<TipsCategories />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/packages" element={<Packages />} />
              <Route path="/all-admins" element={<AllAdmins />} />
              <Route path="/add-admin" element={<AddAdmin />} />
              <Route path="/user-details/:userId" element={<UserDetails />} />
              <Route
                path="/admin-details/:adminId"
                element={<AdminDetails />}
              />
              <Route path="/user-profile" element={<UserProfile />} />
            </Route>
            <Route element={<PublicGuard />}>
              <Route path="/login" element={<Login />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/auth/verifyEmail" element={<VerifyEmail />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
            </Route>
          </Routes>
        </Router>
      </Worker>
    </div>
  );
}

export default App;
