import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { useEffect, useState } from "react";
import "react-activity/dist/library.css";
import "cropperjs/dist/cropper.css";

export default function PreviewTips() {
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");

  const navigate = useNavigate();
  const [programTitle, setProgramTitle] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [categoryDataList, setCategoryDataList] = useState([]);
  const urlId = useParams();

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    api
      .get(`/tips/get-tip-by-id?tipId=${urlId.tipId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setTempUploadedImageUrl(response.data.imageUrl);
        setProgramTitle(response.data.title);
        setCategoryDataList(response.data.categoryDataList);
        setPdfUrl(response.data.pdfUrl);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="addNewProgramPage">
      <div style={{ display: "flex" }}>
        <LeftNavigationBar tips={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate("/tips")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Preview Tip
          </li>
          <form style={{ marginTop: 30, marginBottom: 30 }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: 200,
                  cursor: "pointer",
                }}
              >
                <img
                  src={tempUploadedImageUrl}
                  alt="TipImage"
                  style={{
                    height: 180,
                    width: 180,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <li
                  style={{
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    marginBottom: 5,
                  }}
                >
                  Title
                </li>
                <input
                  required
                  disabled
                  placeholder="Tip Title"
                  value={programTitle}
                  onChange={(e) => setProgramTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
              </div>
            </div>
            <div style={{ width: 500 }}>
              <li
                style={{
                  fontFamily: "LexendSemiBold600",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 25,
                }}
              >
                Categories
              </li>
              {categoryDataList?.map((val, key) => {
                return (
                  <p
                    key={key}
                    style={{
                      marginTop: 4,
                      marginBottom: 4,
                      marginRight: 8,
                      height: 20,
                      padding: 8,
                      background: "white",
                      borderRadius: 5,
                      color: "black",
                      width: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {val.name}
                  </p>
                );
              })}
            </div>
            <div
              style={{
                marginLeft: 10,
                fontFamily: "LexendSemi400",
                fontSize: 20,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              {pdfUrl ? (
                <button
                  style={{
                    display: "block",
                    marginTop: 10,
                    fontFamily: "LexendRegular400",
                    fontSize: 14,
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={pdfUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    View PDF
                  </a>
                </button>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
