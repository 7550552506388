import "./Styles/EditProgramFollowAlong.css";
import { useState, useEffect, useRef } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { BiImage } from "react-icons/bi";
import Compressor from "compressorjs";
import {
  uploadFiles,
  uploadImage,
  isVimeoVideo,
} from "../Components/helpers/fileUpload";

export default function EditProgramFollowAlong() {
  let params = useParams();
  var dayId = params.dayId;
  var dayNumber = params.dayNumber;
  var programId = params.programId;
  const [fileupdated, setFileupdated] = useState();

  //Upload image to AWS Start
  const [file, setFile] = useState(null);

  //Image Upload Starts
  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };
  const [key, setKey] = useState("");

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const handleChangeUploadedImage = async (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setFileupdated(newFile);
      setThumbnail(newFile);

      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);

      const compressedImage = await compressImage(newFile, 800);
      const thumbnailBlob = await compressImage(newFile, 300);
      const fixedAspectRatioImage = new File([compressedImage], newFile.name, {
        type: newFile.type,
      });
      //   const fixedThumbnailImage = new File([thumbnailBlob], fileupdated.name, {
      //     type: fileupdated.type,
      //   });
      setTempUploadedImageUrl(URL.createObjectURL(compressedImage));
      setEditExerciseImageUrl(URL.createObjectURL(compressedImage));
      setFile(fixedAspectRatioImage);
      //   setThumbnail(fixedThumbnailImage);

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setEditExerciseImageUrl(null);
    setFile(null);
    setThumbnail(null);
    setEditExerciseThumbnailImg(null);
  };

  const [dayVideoAvailable, setDayVideoAvailable] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [videoUploadSuccess, setVideoUploadSuccess] = useState(false);
  const [failedUpload, setFailedUplaod] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoUploadProcess, setVideoUploadProcess] = useState(false);
  const hiddenInputVideoUpload = useRef(null);
  const [tempUploadedVideoUrl, setTempUploadedVideoUrl] = useState(null);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);

  const handleClickUploadVideo = (e) => {
    hiddenInputVideoUpload.current.click();
  };

  const handleChangeUploadedVideo = (e) => {
    if (e.target.files.length > 0) {
      setVideoFile(e.target.files[0]);
      const videoUrl = URL.createObjectURL(e.target.files[0]);
      setTempUploadedVideoUrl(videoUrl);
    }
    if (hiddenInputVideoUpload.current) {
      hiddenInputVideoUpload.current.value = "";
    }
  };

  const [deleteExistingVideo, setDeleteExistingVideo] = useState(false);
  const handleDeleteExistingVideo = async () => {
    await api
      .post(
        "/days/update-video-url",
        {
          dayId: dayId,
          dayVideoUrl: null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDeleteExistingVideo(false);
        fetchIndividualDay();
      })
      .catch((err) => {
        setDeleteExistingVideo(false);
      });
  };

  const handleRemoveVideo = () => {
    if (dayVideoAvailable) {
      setDeleteExistingVideo(true);
    } else {
      setVideoFile(null);
      setTempUploadedVideoUrl(null);
    }
  };

  const handleUploadVideo = async () => {
    setVideoUploadProcess(true);
    setIsVideoLoading(true);

    const urlVideo = await uploadFiles(videoFile);
    await api
      .post(
        "/days/update-video-url",
        {
          dayId: dayId,
          dayVideoUrl: urlVideo.Key,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsVideoLoading(false);
        fetchIndividualDay();
        setVideoFile(null);
        setVideoUploadSuccess(true);
        setVideoUploadProcess(false);
      })
      .catch((err) => {
        setFailedUplaod(true);
        setIsVideoLoading(false);
        setVideoUploadProcess(false);
      });
  };
  //Video Upload Ends
  const handleCancelEditExercise = () => {
    setIsEditExercise(false);
    setFile(null);
    setEditExerciseImageUrl(null);
    setEditExerciseThumbnailImg(null);
  };
  //Edit Exercise Image
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [isAddExercise, setIsAddExercise] = useState(false);
  const [isEditExercise, setIsEditExercise] = useState(false);
  const [isDeleteExercise, setIsDeleteExercise] = useState(false);
  const [exerciseTitle, setExerciseTitle] = useState("");
  const [exerciseTime, setExerciseTime] = useState("");
  const [exercises, setExercises] = useState([]);
  const [editExerciseId, setEditExerciseId] = useState(0);
  const [editExerciseTitle, setEditExerciseTitle] = useState("");
  const [editExerciseTime, setEditExerciseTime] = useState("");
  const [editExerciseImageUrl, setEditExerciseImageUrl] = useState(null);
  const [editExerciseThumbnailImg, setEditExerciseThumbnailImg] =
    useState(null);
  const [deleteExerciseId, setDeleteExerciseId] = useState(0);
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";
  let count = 0;

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    api
      .get(`/Days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setExercises(response.data.exercises);
        setTempUploadedVideoUrl(response.data.dayVideoUrl);
        setUploadedVideoUrl(response.data.dayVideoUrl);
        setDayVideoAvailable(response.data.dayVideoUrl);
      });
  }, []);

  const fetchIndividualDay = () => {
    api
      .get(`/Days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setExercises(response.data.exercises);
        setTempUploadedVideoUrl(response.data.dayVideoUrl);
        setUploadedVideoUrl(response.data.dayVideoUrl);
        setDayVideoAvailable(response.data.dayVideoUrl);
      });
  };

  const handleAddExercise = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          `/exercises/create-exercise?dayId=${dayId}`,
          {
            exerciseTitle: exerciseTitle,
            exerciseTime: exerciseTime,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setExerciseTitle("");
          setExerciseTime("");
          fetchIndividualDay();
          setIsAddExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const imageResponse = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);
      await api
        .post(
          `/exercises/create-exercise?dayId=${dayId}`,
          {
            exerciseTitle: exerciseTitle,
            exerciseTime: exerciseTime,
            exerciseImageUrl: imageResponse.Location,
            exerciseThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setExerciseTitle("");
          setExerciseTime("");
          fetchIndividualDay();
          setIsAddExercise(false);
          setTempUploadedImageUrl("");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelAddExercise = () => {
    setIsAddExercise(false);
    setFile(null);
    setTempUploadedImageUrl("");
    setExerciseTitle("");
    setExerciseTime("");
  };

  function fetchIndividualExercise(exerciseId) {
    api
      .get(`/exercises/get-individual-exercise?exerciseId=${exerciseId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setEditExerciseTitle(response.data.exerciseTitle);
        setEditExerciseTime(response.data.exerciseTime);
        setEditExerciseImageUrl(response.data.exerciseImageUrl);
        setEditExerciseThumbnailImg(response.data.exerciseThumbnailImageUrl);
      });
  }

  function handleEditExerciseInitial(exerciseId) {
    setIsEditExercise(true);
    setEditExerciseId(exerciseId);
    fetchIndividualExercise(exerciseId);
  }

  async function handleEditExerciseFinal(e) {
    e.preventDefault();
    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          "/exercises/edit-exercise",
          {
            exerciseId: editExerciseId,
            exerciseTitle: editExerciseTitle,
            exerciseTime: editExerciseTime,
            exerciseImageUrl: editExerciseImageUrl,
            exerciseThumbnailImageUrl: editExerciseThumbnailImg,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          fetchIndividualDay();
          setIsEditExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const { Location } = await uploadImage(file);
      const thumbnailResponse = await uploadImage(thumbnail);

      await api
        .post(
          "/exercises/edit-exercise",
          {
            exerciseId: editExerciseId,
            exerciseTitle: editExerciseTitle,
            exerciseTime: editExerciseTime,
            exerciseImageUrl: Location,
            exerciseThumbnailImageUrl: thumbnailResponse.Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          fetchIndividualDay();
          setIsEditExercise(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }

  function handleDeleteExerciseInitial(exerciseId) {
    setIsDeleteExercise(true);
    setDeleteExerciseId(exerciseId);
  }

  const handleDeleteExerciseFinal = async () => {
    await api
      .post(`/exercises/delete-exercise?exerciseId=${deleteExerciseId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        fetchIndividualDay();
        setIsDeleteExercise(false);
      });
  };
  return (
    <div className="editProgramFollowAlongPage">
      {isAddExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal" onSubmit={handleAddExercise}>
            {/* <li style={{  fontFamily: 'LexendMedium500', fontSize: 18, marginTop: 35, marginLeft: 20 }}>Add New Exercise</li> */}
            <div
              style={{
                display: "flex",
                marginLeft: 20,
                marginRight: 20,
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              {tempUploadedImageUrl.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    cursor: "pointer",
                    width: 250,
                    marginTop: 10,
                    background: "#ffffff",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{
                      maxHeight: 100,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 20,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    Upload thumb of exercise
                  </li>
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 160,
                        height: 140,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div style={{ marginLeft: 20, marginTop: 10 }}>
                <input
                  required
                  placeholder="Exercise Title"
                  value={exerciseTitle}
                  onChange={(e) => setExerciseTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 50,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <input
                  required
                  placeholder="Exercise Time"
                  value={exerciseTime}
                  onChange={(e) => setExerciseTime(e.target.value)}
                  style={{
                    width: 340,
                    height: 50,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                    marginTop: 25,
                  }}
                />
                <li style={{ fontSize: 12, marginTop: 5 }}>*Format: MM:SS</li>
              </div>
            </div>

            {!isLoading ? (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                Add
              </button>
            ) : (
              <button
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "#ffffff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 18,
                  borderRadius: 4,
                  marginTop: 20,
                  marginBottom: 10,
                  width: 150,
                  height: 45,
                }}
              >
                <Spinner
                  size={15}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "white",
                  }}
                />
              </button>
            )}
            <button
              onClick={handleCancelAddExercise}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 35,
                width: 150,
                height: 45,
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}

      {isEditExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <form id="modal" onSubmit={handleEditExerciseFinal}>
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 18,
                marginTop: 35,
                marginLeft: 20,
              }}
            >
              Edit Exercise Details
            </li>
            <div
              style={{
                display: "flex",
                marginLeft: 20,
                marginRight: 20,
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              {editExerciseImageUrl === null ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    cursor: "pointer",
                    width: 250,
                    marginTop: 10,
                    background: "#ffffff",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{
                      maxHeight: 100,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 20,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    Upload thumb of exercise
                  </li>
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={editExerciseImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 160,
                        height: 140,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div style={{ marginLeft: 20, marginTop: 10 }}>
                <input
                  required
                  placeholder="Exercise Title"
                  value={editExerciseTitle}
                  onChange={(e) => setEditExerciseTitle(e.target.value)}
                  style={{
                    width: 340,
                    height: 50,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <input
                  required
                  placeholder="Exercise Time"
                  value={editExerciseTime}
                  onChange={(e) => setEditExerciseTime(e.target.value)}
                  style={{
                    width: 340,
                    height: 50,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                    marginTop: 25,
                  }}
                />
              </div>
            </div>
            <>
              {!isLoading ? (
                <button
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    cursor: "pointer",
                    backgroundColor: "#F38CAC",
                    color: "#ffffff",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 20,
                    paddingLeft: 20,
                    border: 0,
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    borderRadius: 4,
                    marginTop: 20,
                    marginBottom: 10,
                    width: 150,
                    height: 45,
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    cursor: "pointer",
                    backgroundColor: "#F38CAC",
                    color: "#ffffff",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 20,
                    paddingLeft: 20,
                    border: 0,
                    fontFamily: "LexendSemiBold600",
                    fontSize: 18,
                    borderRadius: 4,
                    marginTop: 20,
                    marginBottom: 10,
                    width: 150,
                    height: 45,
                  }}
                >
                  <Spinner
                    size={15}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "white",
                    }}
                  />
                </button>
              )}
            </>

            <button
              onClick={handleCancelEditExercise}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                borderRadius: 4,
                marginTop: 20,
                marginBottom: 35,
                width: 150,
                height: 45,
              }}
            >
              Cancel
            </button>
          </form>
        </>
      )}
      {isDeleteExercise && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center", marginTop: 50 }}>
              Are you sure you want to delete
            </li>
            <li style={{ textAlign: "center" }}>this exercise?</li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={handleDeleteExerciseFinal}
                style={{
                  marginRight: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#ffba26",
                  color: "#0a2e28",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteExercise(false)}
                style={{
                  marginLeft: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#e63946",
                  color: "white",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      {videoUploadSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center", marginTop: 50 }}>
              Video has been
            </li>
            <li style={{ textAlign: "center" }}>uploaded successfully!</li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={() => setVideoUploadSuccess(false)}
                style={{
                  marginLeft: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#e63946",
                  color: "white",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </>
      )}

      {deleteExistingVideo && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li style={{ textAlign: "center", marginTop: 50 }}>
              Are you sure you want to delete
            </li>
            <li style={{ textAlign: "center" }}>existing Video?</li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={handleDeleteExistingVideo}
                style={{
                  marginRight: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#ffba26",
                  color: "#0a2e28",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setDeleteExistingVideo(false)}
                style={{
                  marginLeft: 10,
                  width: 70,
                  cursor: "pointer",
                  background: "#e63946",
                  color: "white",
                  borderStyle: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}

      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
              Follow Along
            </li>
            {/* <button onClick={() => navigate(`/edit-program/follow-along/${programId}/${dayId}/${dayNumber}/home-clip`)} style={{ width: 140, height: 50, display: 'block',   backgroundColor: '#F38CAC', color: '#ffffff', fontFamily: 'LexendBold700', fontSize: 18,  borderStyle: 'none' }}>Home Clip</button> */}
          </div>
          <input
            key={key}
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <input
            type="file"
            accept="video/mp4"
            ref={hiddenInputVideoUpload}
            onChange={handleChangeUploadedVideo}
            style={{ display: "none" }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 40,
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: 520, borderStyle: "none" }}>
              <li
                style={{
                  fontFamily: "LexendSemiBold600",
                  fontSize: 24,
                  marginBottom: 25,
                }}
              >
                Exercise Included
              </li>
              <div style={{}}>
                {exercises.map((item, key) => {
                  count = key + 1;
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 40,
                        fontFamily: "LexendMedium500",
                        fontSize: 16,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 30,
                          width: 120,
                          height: 120,
                          backgroundColor: "#ffffff",
                          borderStyle: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.exerciseImageUrl !== null ? (
                          <img
                            src={item.exerciseImageUrl}
                            alt="item"
                            style={{ width: 120, height: 120, resize: "cover" }}
                          />
                        ) : (
                          <BiImage size={50} style={{ color: "#A5D7E8" }} />
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: 30,
                          width: 300,
                          height: 120,
                          borderStyle: "none",
                        }}
                      >
                        <div
                          style={{
                            height: 50,
                            borderStyle: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "white",
                          }}
                        >
                          {item.exerciseTitle}
                        </div>
                        <div
                          style={{
                            marginTop: 15,
                            height: 50,
                            borderStyle: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "white",
                          }}
                        >
                          {item.exerciseTime}
                        </div>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          onClick={() =>
                            handleEditExerciseInitial(item.exerciseId)
                          }
                          style={{
                            cursor: "pointer",
                            marginTop: 20,
                            marginBottom: 10,
                            width: 35,
                            height: 35,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderStyle: "none",
                          }}
                        >
                          <FaRegEdit size={20} />
                        </div>
                        <div
                          onClick={() =>
                            handleDeleteExerciseInitial(item.exerciseId)
                          }
                          style={{
                            cursor: "pointer",
                            width: 35,
                            height: 35,
                            borderRadius: 20,
                            borderStyle: "solid",
                            borderColor: "#FF0000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <RiDeleteBin6Line
                            size={20}
                            style={{ color: "#FF0000" }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button
                onClick={() => setIsAddExercise(true)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                  height: 50,
                  width: "100%",
                  borderStyle: "none",
                }}
              >
                <AiOutlinePlus size={18} />
                <li
                  style={{
                    fontFamily: "LexendMedium500",
                    fontSize: 16,
                    marginLeft: 10,
                  }}
                >
                  Add New Exercise
                </li>
              </button>
            </div>
            <div>
              {tempUploadedVideoUrl === null ? (
                <div
                  onClick={handleClickUploadVideo}
                  style={{
                    cursor: "pointer",
                    width: 250,
                    height: 250,
                    marginTop: 10,
                    background: "#ffffff",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{ maxHeight: 100, marginTop: 80, marginLeft: 110 }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Video
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    Upload the whole video of the day
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "black",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    *Only mp4 format is supported
                  </li>
                </div>
              ) : (
                <div style={{ borderStyle: "none", width: "100%" }}>
                  {uploadedVideoUrl ? (
                    <>
                      {/* <VimeoPlayer
                        video={tempUploadedVideoUrl}
                        autoplay
                        className="video-wrapper"
                      /> */}
                      <video
                        width="250"
                        height="200"
                        controls
                        className="video-wrapper"
                      >
                        <source
                          src={`${
                            VIDEO_URL + isVimeoVideo(tempUploadedVideoUrl)
                          }`}
                        />
                      </video>
                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 10,
                          fontFamily: "LexendRegular400",
                          fontSize: 14,
                        }}
                      >
                        Remove Video
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                  {videoFile &&
                    (!isVideoLoading ? (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        <button
                          onClick={handleRemoveVideo}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 10,
                            fontFamily: "LexendRegular400",
                            fontSize: 14,
                          }}
                        >
                          Remove Video
                        </button>
                        <button
                          onClick={handleUploadVideo}
                          style={{
                            marginTop: 20,
                            display: "block",
                            width: 250,
                            height: 35,
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontFamily: "LexendBold800",
                            fontSize: 18,
                            backgroundColor: "#4176FF",
                            color: "white",
                            borderStyle: "none",
                          }}
                        >
                          Save Video
                        </button>
                      </>
                    ) : (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        {!videoUploadProcess ? (
                          <button
                            onClick={handleRemoveVideo}
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: 10,
                              fontFamily: "LexendRegular400",
                              fontSize: 14,
                            }}
                          >
                            Remove Video
                          </button>
                        ) : (
                          <button
                            onClick={handleRemoveVideo}
                            className="remove-save-btn"
                            style={{
                              visibility: "hidden",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: 10,
                              fontFamily: "LexendRegular400",
                              fontSize: 14,
                            }}
                          >
                            Remove Video
                          </button>
                        )}
                        <button
                          onClick={handleUploadVideo}
                          style={{
                            marginTop: 20,
                            width: 250,
                            height: 35,
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            fontFamily: "LexendBold800",
                            fontSize: 18,
                            backgroundColor: "#4176FF",
                            color: "white",
                            borderStyle: "none",
                          }}
                        >
                          <Spinner
                            size={15}
                            style={{
                              color: "white",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </button>
                      </>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
