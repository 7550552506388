import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: "us-west-1",
  signatureVersion: "v4",
});
const s3 = new AWS.S3();

export const uploadFiles = async (videoFile) => {
  const fileSize = videoFile.size;
  console.log(process.env.REACT_APP_ACCESS_KEY);

  const partSize = 20 * 1024 * 1024;
  const bucketFolderName = "musclesisters-videos/videos";

  const key = `${Date.now()}${videoFile.name ?? "convertgif.mp4"}`;

  let multipartCreateResult = null;
  try {
    multipartCreateResult = await s3
      .createMultipartUpload({
        Bucket: bucketFolderName,
        Key: key,
      })
      .promise();

    let start = 0;
    let partNumber = 1;
    const parts = [];

    while (start < fileSize) {
      const end = Math.min(start + partSize, fileSize);
      const chunk = videoFile.slice(start, end);

      const { ETag } = await s3
        .uploadPart({
          Bucket: bucketFolderName,
          Key: key,
          PartNumber: partNumber,
          UploadId: multipartCreateResult.UploadId,
          Body: chunk,
        })
        .promise();

      if (ETag) {
        parts.push({ ETag, PartNumber: partNumber });
      } else {
        throw new Error(`ETag is missing for part ${partNumber}`);
      }

      start = end;
      partNumber++;
    }

    const compliteUpload = await s3
      .completeMultipartUpload({
        Bucket: bucketFolderName,
        Key: key,
        MultipartUpload: {
          Parts: parts,
        },
        UploadId: multipartCreateResult.UploadId,
      })
      .promise();
    console.log("key::", key);
    console.log("send Key", compliteUpload.Key);

    console.log("compliteUpload::", compliteUpload);

    return compliteUpload;
  } catch (error) {
    console.error(error);
    if (multipartCreateResult && multipartCreateResult.UploadId) {
      const params = {
        Bucket: bucketFolderName,
        Key: key,
        UploadId: multipartCreateResult.UploadId,
      };
      const abord = await s3.abortMultipartUpload(params).promise();
      console.log("abord::", abord);
    }
    return null;
  }
};

export const uploadImage = async (imageFile) => {
  try {
    const params = {
      Bucket: "muscle-sisters-bucket",
      Key: `${Date.now()}${imageFile.name}`,
      Body: imageFile,
    };
    console.log("params ;:", params);

    return await s3.upload(params).promise();
  } catch (error) {
    console.log(error, "error");
  }
};

export const isVimeoVideo = (url) => {
  if (url.includes("vimeo")) {
    return "videos/" + url.split("/").pop() + ".mp4";
  }
  return url;
};
