import { useRef, useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/Packages.css";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import api from "../Contexts/BaseUrl";

export default function Equipments() {
  const [token, setToken] = useState(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState(0);
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageValidity, setPackageValidity] = useState(0);
  const [packageUnit, setPackageUnit] = useState("");
  const [validationMessage, setValidationMessage] = useState(""); // New state variable for validation message
  const [packageMessage, setPackageMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // New state variable for the success pop-up

  var user = JSON.parse(localStorage.getItem("auth"));
  useEffect(() => {
    if (user) {
      setToken(user.token);
    }
  }, []);
  useEffect(() => {
    if (token) fetchPackages();
  }, [token]);

  const fetchPackages = () => {
    api
      .get(`/subscriptionPackages/get-all-packages`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPackages(response.data);
      });
  };

  function fetchIndividualPackage(packageId) {
    api
      .get(
        `/subscriptionPackages/get-individual-package?packageId=${packageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setPackageId(response.data.subscriptionPackageId);
        setPackageName(response.data.subscriptionPackageName);
        setPackagePrice(response.data.subscriptionPackagePrice);
        setPackageValidity(response.data.subscriptionPackageValidityDays);
        setPackageUnit(response.data.subscriptionPackageUnit);
      });
  }

  function handleEdit(packageId) {
    setIsEdit(true);
    fetchIndividualPackage(packageId);
  }



  const handleUpdatePackage = async (e) => {
    e.preventDefault();
    const parsedPrice = parseFloat(packagePrice);

    if (parsedPrice <= 0 || isNaN(parsedPrice)) {
      setPackageMessage("Package price must be a positive number.");
      setValidationMessage("");
      return;
    }
    if (parseFloat(packagePrice) < 0) {
      setValidationMessage("Package price cannot be 0 or negative.");
      return;
    }
    setValidationMessage("");
    if (packageId === 1 && (parseInt(packageValidity) < 1 || parseInt(packageValidity) > 31)) {
      setValidationMessage("Package validity must be between 1 and 31 days.");
      return;

    }
    else if (packageId === 2 && (parseInt(packageValidity) < 1 || parseInt(packageValidity) > 365)) {
      setValidationMessage("Package validity must be between 1 and 365 days.");
      return;
    }

    else if (packageId === 3 && (parseInt(packageValidity) < 1 || parseInt(packageValidity) > 1825)) {
      setValidationMessage("Package validity must be between 1 and 1825 days.");
      return;
    }

    setIsLoading(true);
    await api
      .post(
        "/subscriptionPackages/edit-package",
        {
          subscriptionPackageId: packageId,
          subscriptionPackageName: packageName,
          subscriptionPackagePrice: packagePrice,
          subscriptionPackageValidityDays: packageValidity,
          subscriptionPackageUnit: packageUnit,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((response) => {
        fetchPackages();
        setIsEdit(false);
        setIsLoading(false);
        setShowSuccessPopup(true);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
      });

  };

  const closeSuccessPopup = () => {
    // Close the success pop-up
    setShowSuccessPopup(false);
  };

  const handleBack = (e) => {
    e.preventDefault();
    fetchPackages();
    setIsEdit(false);
    setValidationMessage("");
  };

  return (
    <div className="packagePage" style={{ height: windowSize.current[1] }}>
      <LeftNavigationBar packages={1} />

      {isEdit && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li style={{ fontSize: 22 }}>Edit Package Details</li>
            <div style={{ display: "flex", marginTop: 20, marginBottom: 10 }}>
              <li style={{ width: 190, marginTop: 10 }}>Package Name:</li>
              <input
                placeholder="Type Package Name"
                style={{
                  width: 200,
                  padding: 10,
                  fontFamily: "LexendMedium500",
                }}
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", marginTop: 20, marginBottom: 10 }}>
              <li style={{ width: 190, marginTop: 10 }}>Package Price:</li>
              <input
                placeholder="Type Package Name"
                style={{
                  width: 200,
                  padding: 10,
                  fontFamily: "LexendMedium500",
                }}
                value={packagePrice}
                onChange={(e) => setPackagePrice(e.target.value)}
              />
            </div>
            {packageMessage && (
              <div style={{ color: "red", marginLeft: 190 }}>
                {packageMessage}
              </div>
            )}

            <div style={{ display: "flex", marginTop: 20, marginBottom: 10 }}>
              <li style={{ width: 190, marginTop: 10 }}>
                Package Validity: (Days)
              </li>
              <input
                placeholder="Type Package Name"
                style={{
                  width: 200,
                  padding: 10,
                  fontFamily: "LexendMedium500",
                }}
                value={packageValidity}
                onChange={(e) => setPackageValidity(e.target.value)}
              />
            </div>
            {validationMessage && (
              <div style={{ color: "red", marginLeft: 190 }}>
                {validationMessage}
              </div>
            )}

            {!isLoading ? (
              <button
                onClick={handleUpdatePackage}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "white",
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 120,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                  borderRadius: 4,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 15,
                  marginTop: 40,
                }}
              >
                Update
              </button>
            ) : (
              <button
                onClick={handleUpdatePackage}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "white",
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 120,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                  borderRadius: 4,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 15,
                  marginTop: 40,
                }}
              >
                <Spinner
                  size={14}
                  style={{
                    color: "white",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                />
              </button>
            )}
            <button
              onClick={handleBack}
              style={{
                cursor: "pointer",
                backgroundColor: "#FFF2F2",
                color: "#FF0000",
                paddingTop: 8,
                paddingBottom: 8,
                width: 120,
                border: 0,
                fontFamily: "LexendSemiBold600",
                fontSize: 16,
                borderRadius: 4,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {showSuccessPopup && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal-1">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Package Details has been
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              updated successfully!
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={closeSuccessPopup}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#F38CAC",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  border: 0,
                  fontFamily: "LexendSemiBold600",
                  fontSize: 16,
                  borderRadius: 4,
                  display: "block",
                  marginLeft: 40,
                  marginTop: 10,
                }}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
      <div className="mainBody">
        <div
          style={{
            marginTop: 40,
            display: "flex",
            justifyContent: "space-between",
            width: 720,
            flexDirection: "column",
          }}
        >
          <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
            Subscription Packages
          </li>
          <p
            style={{
              textAlign: "left",
              fontFamily: "LexendBold400",
              fontSize: 16,
              color: "#88869A",
            }}
          >
            Note: There will be a free trial plan with all plans.
          </p>
        </div>
        {packages.map((val, key) => {
          return (
            val.subscriptionPackageId != 4 && (
              <div
                id="packageBox"
                key={key}
                style={{
                  width: 720,
                  borderStyle: "none",
                  fontFamily: "LexendMedium500",
                  marginTop: 20,
                  marginBottom: 20,
                  background: "white",
                  borderRadius: 5,
                  padding: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 18,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <li style={{ width: 190 }}>Package Name:</li>
                    <li>{val.subscriptionPackageName}</li>
                  </div>
                  <button
                    onClick={() => handleEdit(val.subscriptionPackageId)}
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 16,
                      width: 80,
                      background: "#FFF2F2",
                      color: "#FF0000",
                      borderStyle: "none",
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginTop: 0,
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div
                  style={{ display: "flex", fontSize: 18, marginBottom: 20 }}
                >
                  <li style={{ width: 190 }}>Package Price:</li>
                  <li>${val.subscriptionPackagePrice}</li>
                </div>
                <div style={{ display: "flex", fontSize: 18, marginBottom: 0 }}>
                  <li style={{ width: 190 }}>Package Validity:</li>
                  <li>{val.subscriptionPackageValidityDays} Days</li>
                </div>
              </div>
            )
          );
        })}

        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
}
