import { useRef, useState, useEffect } from 'react';
import LeftNavigationBar from '../Components/LeftNavigationBar';
import './Styles/Categories.css';
import { Spinner } from 'react-activity';
import "react-activity/dist/library.css";
import api from '../Contexts/BaseUrl';
import { useNavigate } from 'react-router-dom';
import deleteIcon from '../Images/Equipments/delete.png';
import backIcon from '../Images/AddAdmin/back.png';
import { toast } from 'react-toastify';

export default function TipsCategories() {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        var user = JSON.parse(localStorage.getItem('auth'));
        if (user) {
            setToken(user.token);
        }
        api.get('/tips/get-category-list', {
            headers: { Authorization: `Bearer ${user.token}` }
        }).then(response => {
            setAllCategories(response.data);
        })
    }, [])

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [categoryName, setCategoryName] = useState('');
    const [allCategories, setAllCategories] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isError, setIsError] = useState(false);

    const fetchAllEquipments = () => {
        api.get('/tips/get-category-list', {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setAllCategories(response.data);
        })
    }

    const handleAddCategory = async (e) => {
        e.preventDefault();
        await api.post(`/tips/create-category`, {
            name: categoryName
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setIsSuccessful(true);
            setIsLoading(false);
            setCategoryName('');
            fetchAllEquipments();
            toast.success("Category Created Successfully", {
                position: "top-right",
                autoClose: 2000,
                theme: "dark",
            });
        }).catch(err => {
            setIsError(true);
            setIsLoading(false);
        })
    }

    const [isDeleteRecipe, setIsDeleteRecipe] = useState(false);
    const [deleteRecipetId, setDeleteRecipetId] = useState(0);

    const handleDeleteEquipmentInitial = (element) => {
        setIsDeleteRecipe(true);
        setDeleteRecipetId(element);
    }

    const handleDeleteEquipmentFinal = async () => {
        await api.delete(`/tips/delete-category?categoryid=${deleteRecipetId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setIsDeleteRecipe(false);
            fetchAllEquipments();
            toast.success("Category Deleted Successfully", {
                position: "top-right",
                autoClose: 2000,
                theme: "dark",
            });
        }).catch(err => {
            setIsDeleteRecipe(false);
            toast.error("Unable to delete Category due to existing tips assigned to this category", {
                position: "top-right",
                autoClose: 2000,
                theme: "dark",
            });
        })
    }

    return (
        <div className='categoriesPage' style={{ height: windowSize.current[1] }}>
            <LeftNavigationBar tips={1} />

            {isDeleteRecipe &&
                <>
                    <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3, zIndex: 4 }} />
                    <div id='modal'>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginTop: 35 }}>Are you sure you want to delete</li>
                        <li style={{ textAlign: 'center', fontFamily: 'LexendMedium500', marginBottom: 20 }}>this category?</li>
                        <div style={{ marginLeft: 100 }}>
                            <button onClick={() => handleDeleteEquipmentFinal()} style={{ cursor: 'pointer', backgroundColor: '#ffba26', color: '#0a2e28', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>Yes</button>
                            <button onClick={() => setIsDeleteRecipe(false)} style={{ cursor: 'pointer', backgroundColor: '#e63946', color: 'white', paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20, marginRight: 30, border: 0, fontFamily: 'LexendMedium500', fontSize: 14, borderRadius: 4 }}>No</button>
                        </div>
                    </div>
                </>
            }
            <div className='mainBody'>
                <div onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', width: 40, height: 40, borderRadius: 25, borderStyle: 'solid', borderColor: '#F38CAC', borderWidth: 1 }}>
                    <img src={backIcon} alt='backicon' style={{ maxHeight: 12 }} />
                </div>
                <li style={{ fontFamily: 'LexendBold700', fontSize: 28 }}>All Categories</li>
                <div style={{ marginTop: 25, display: 'flex', flexWrap: 'wrap' }}>
                    {allCategories.map((val, key) => {
                        return (
                            <div id='equipmentBox' key={key}>

                                <li style={{ marginLeft: 15, fontFamily: 'LexendBold700', fontSize: 14 }}>{val.name}</li>
                                <img onClick={() => handleDeleteEquipmentInitial(val.id)} src={deleteIcon} alt='deleteIcon' style={{ maxHeight: 15, marginTop: -45, cursor: 'pointer', marginRight: 15 }} />


                            </div>
                        )
                    })}
                </div>

                <div style={{ marginTop: 40 }}>
                    <li style={{ fontFamily: 'LexendBold700', fontSize: 28 }}>Add Category</li>
                    <form onSubmit={handleAddCategory} style={{ display: 'flex', marginTop: 25 }}>

                        <div style={{ width: 300, marginLeft: 0 }}>
                            <input
                                type='text'
                                placeholder="Type Category name"
                                value={categoryName}
                                onChange={e => setCategoryName(e.target.value)}
                                required
                                style={{
                                    width: 280,
                                    height: 60,
                                    backgroundColor: '#ffffff',
                                    borderStyle: 'none',
                                    paddingLeft: 20,
                                    fontFamily: 'LexendRegular400',
                                    fontSize: 14
                                }}
                            />
                            {!isLoading ?
                                <button type="submit" style={{ marginTop: 28, width: '100%', height: 60, backgroundColor: '#F38CAC', borderStyle: 'none', color: 'white', fontFamily: 'LexendBold700', fontSize: 18 }}>Save</button>
                                :
                                <button type='button' style={{ marginTop: 28, width: '100%', height: 60, backgroundColor: '#F38CAC', borderStyle: 'none', color: 'white', fontFamily: 'LexendBold700', fontSize: 18 }}>
                                    <Spinner size={15} color='white' style={{ diplay: 'block', margin: 'auto' }} />
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}