import { useRef, useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/Equipments.css";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import api from "../Contexts/BaseUrl";
import homeIcon from "../Images/LeftBarIcons/Home.png";
import deleteIcon from "../Images/Equipments/delete.png";
import { uploadImage } from "../Components/helpers/fileUpload";

export default function Equipments() {
  const [token, setToken] = useState(null);

  const [file, setFile] = useState(null);

  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
  };

  // const handleChangeUploadedImage = (e) => {
  //     if(e.target.files.length> 0){
  //         setFile(e.target.files[0]);
  //         const imageUrl = URL.createObjectURL(e.target.files[0]);
  //         setTempUploadedImageUrl(imageUrl);
  //     }
  // }

  const [key, setKey] = useState("");
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);

      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);

      setTempUploadedImageUrl(URL.createObjectURL(newFile));

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    api
      .get("/equipments/get-all-equipments", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setAllEquipments(response.data);
      });
  }, []);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [equipmentName, setEquipmentName] = useState("");
  const [allEquipments, setAllEquipments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchAllEquipments = () => {
    api
      .get("/equipments/get-all-equipments", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllEquipments(response.data);
      });
  };

  const handleAddEquipment = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (file === null) {
      await api
        .post(
          `/equipments/create-equipment`,
          {
            equipmentName: equipmentName,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsSuccessful(true);
          setIsLoading(false);
          setEquipmentName("");
          fetchAllEquipments();
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      const { Location } = await uploadImage(file);

      await api
        .post(
          `/equipments/create-equipment`,
          {
            equipmentName: equipmentName,
            equipmentImage: Location,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setIsSuccessful(true);
          setIsLoading(false);
          setEquipmentName("");
          setTempUploadedImageUrl("");
          setFile(null);
          fetchAllEquipments();
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  const [isDeleteEquipment, setIsDeleteEquipment] = useState(false);
  const [deleteEquipmentId, setDeleteEquipmentId] = useState(0);

  const handleDeleteEquipmentInitial = (element) => {
    setIsDeleteEquipment(true);
    setDeleteEquipmentId(element);
  };

  const handleDeleteEquipmentFinal = async () => {
    await api
      .post(`/equipments/delete-equipment?equipmentId=${deleteEquipmentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsDeleteEquipment(false);
        fetchAllEquipments();
      })
      .catch((err) => {
        console.log(err.response.status);
        console.log("Failed deleting equipment");
      });
  };

  return (
    <div className="equipmentsPage" style={{ height: windowSize.current[1] }}>
      <LeftNavigationBar equipments={1} />

      {isDeleteEquipment && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Are you sure you want to delete
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              this equipment?
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={() => handleDeleteEquipmentFinal()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteEquipment(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      <div className="mainBody">
        <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
          All Equipments
        </li>
        <div style={{ marginTop: 25, display: "flex", flexWrap: "wrap" }}>
          {allEquipments.map((val, key) => {
            return (
              <div id="equipmentBox" key={key}>
                <div
                  style={{
                    borderStyle: "none",
                    marginLeft: 20,
                    marginRight: 10,
                    marginBottom: 10,
                    height: 80,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={val.equipmentImage}
                    alt="equipment"
                    style={{ marginTop: 30, width: 100, height: 50 }}
                  />
                  <img
                    onClick={() =>
                      handleDeleteEquipmentInitial(val.equipmentId)
                    }
                    src={deleteIcon}
                    alt="deleteIcon"
                    style={{ maxHeight: 15, marginTop: 15, cursor: "pointer" }}
                  />
                </div>
                <li
                  style={{
                    marginLeft: 20,
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                  }}
                >
                  {val.equipmentName}
                </li>
              </div>
            );
          })}
        </div>
        <input
          key={key}
          type="file"
          accept="image/*"
          ref={hiddenInputImageUpload}
          onChange={handleChangeUploadedImage}
          style={{ display: "none" }}
        />
        <div style={{ marginTop: 40 }}>
          <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
            Add Equipment
          </li>
          <form
            onSubmit={handleAddEquipment}
            style={{ display: "flex", marginTop: 25 }}
          >
            {tempUploadedImageUrl.length === 0 ? (
              <div id="uploadImageBox" onClick={handleClickUploadImage}>
                <img
                  src={uploadImageIcon}
                  alt="uploadImageIcon"
                  style={{
                    maxHeight: 150,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 40,
                  }}
                />
                <li
                  style={{
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  Upload Image
                </li>
                <li
                  style={{
                    fontFamily: "LexendMedium500",
                    fontSize: 12,
                    color: "#8f8d9f",
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Upload thumb of equipment
                </li>
              </div>
            ) : (
              <div>
                <div
                  onClick={handleClickUploadImage}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={tempUploadedImageUrl}
                    alt="uploaded"
                    style={{ maxHeight: 150, resize: "cover" }}
                  />
                </div>
                <button
                  onClick={handleCancelImage}
                  style={{
                    backgroundColor: "white",
                    paddingTop: 5,
                    paddingBottom: 5,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 10,
                  }}
                >
                  Remove Image
                </button>
              </div>
            )}
            <div style={{ width: 300, marginLeft: 30 }}>
              <input
                type="text"
                placeholder="Equipment's name"
                value={equipmentName}
                onChange={(e) => setEquipmentName(e.target.value)}
                required
                style={{
                  width: 280,
                  height: 60,
                  backgroundColor: "#ffffff",
                  borderStyle: "none",
                  paddingLeft: 20,
                  fontFamily: "LexendRegular400",
                  fontSize: 14,
                }}
              />
              {!isLoading ? (
                <button
                  style={{
                    marginTop: 28,
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F38CAC",
                    borderStyle: "none",
                    color: "white",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  style={{
                    marginTop: 28,
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F38CAC",
                    borderStyle: "none",
                    color: "white",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                  }}
                >
                  <Spinner
                    size={15}
                    color="white"
                    style={{ diplay: "block", margin: "auto" }}
                  />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
